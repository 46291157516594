import React, { useState } from "react";

import expertise from "../Images/Service/Group 1686553705.png";
import individual from "../Images/Service/10384071731582806947 1.png";
import termscondition from "../Images/Service/tag-svgrepo-com 1.png";
import enterprise from "../Images/Service/13171693581551938534 1.png";
import facility from "../Images/Service/12421363271594941310 1.png";
import { Link } from "react-router-dom";
import sliderlogo1 from "../Images/Sliderlogo/Eicher-logo-1920x1080.png";

import sliderlogo5 from "../Images/Sliderlogo/Maharshi-Vitiligo-Centres-Logo 2.png";
import sliderlogo6 from "../Images/Sliderlogo/RSFP-logo.png";
import sliderlogo7 from "../Images/Sliderlogo/Sun-Pharma.png";
import sliderlogo8 from "../Images/Sliderlogo/cropped-cropped-cbn-india-logo.png";
import sliderlogo9 from "../Images/Sliderlogo/logonavbar_yolo 2.png";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "../Css/Card.css";
import { cardData } from "../Constant/cardData";
import OurCollaborativeVentures from "../Components/OurCollaborativeVentures";

const Services = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
  };
  console.log(cardData);
  return (
    <div className="container-fluid">
      <Helmet>
        <link rel="canonical" href="https://rashailtechlabs.co.in/services" />

        <title>Our Services - Comprehensive IoT & Automation Solutions</title>

        <meta
          name="description"
          title="Explore Our Range of IoT Solutions and Automation Services - Rashail Tech Labs"
          content="Explore the diverse range of IoT solutions and automation services offered by Rashail Tech Labs. From software development to digital transformation strategies, we have the expertise to help your business succeed in today's digital landscape."
        />
      </Helmet>
      <div className="row servicetopbackground">
        <div className="col-md-11 col-sm-12 text-center mt-5 mx-auto">
          <h1 className=" serviceheading mt-5 ">
            <span className="serviceheading-color">Our </span> Services{" "}
          </h1>
          <p className="serviceparaheading mt-4 mb-5">
            "Further your company's interests with innovative technology and IT
            services. "
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <h2 className="mainheading mt-5 h1">
            Next-generation{" "}
            <span className="mainheading-color"> digital services </span> and{" "}
            <br />
            <span className="mainheading-color "> consulting</span>{" "}
          </h2>
          <p className="paraheading mt-4">
            We help you transform your business with
            <br />
            advanced technologies.
          </p>

          <div className="mx-24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-32 md:gap-y-40 md:gap-x-8 mt-5  justify-center items-center">
  {cardData.map((card, index) => (
    <div key={index} className="col-span-1 sm:col-span-1 md:col-span-1 mb-8">
      <div
        className={`card borderless-card home-business-card ${
          isFlipped ? "flipped" : ""
        }`}
        style={{
          width: "100%",
          position: "relative",
          perspective: "1500px",
        }}
        onMouseEnter={handleCardFlip}
        onMouseLeave={handleCardFlip}
      >
        <div className="card-inner">
          <div className="card-front">
            <img
              src={card.image}
              className="card-img-top h-36 shadow-xl rounded-xl"
              alt={card.title}
            />
          </div>
          <div className="card-back">
            <div className="text-black md:h-36 bg-white rounded-xl bg-opacity-50 text-center flex items-center justify-center shadow-gray-500 shadow-xl p-2 overflow-auto">
              {card.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

        </div>

        <div className="button-container mt-20 mb-5">
          <Link to="/contactus">
          <button className="button mt-5">Grow your business</button>
          </Link>
        </div>
      </div>
      <div className="row justify-content-center">
        <h4 className="mainheading text-center mb-3 mt-4">
          Our Expertise <span className="mainheading-color">Your Business</span>{" "}
        </h4>
        <div className="col-md-5">
          <div className="row mt-5">
            <div className="col-md-6">
              <img
                src={individual}
                alt="Team of IoT Professionals"
                title="IoT Professionals"
                className="img-fluid"
              />
              <h5>Cutting-Edge Technology</h5>
              <p>
                When we say 'cutting-edge', we mean it. We make use of
                top-of-the-line technologies to power the custom solutions that
                we deliver to our clients. This includes precision engineering
                as well as present and future advancements in AI, IoT
                technology.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={termscondition}
                alt="Landingimg"
                className="img-fluid"
              />
              <h5>Seasoned In-house team</h5>
              <p>
                Armoured with our team of IT professionals, our code is an
                embodiment of perfection, fitting nicely in your products and
                custom solutions.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <img src={enterprise} alt="Landingimg" className="img-fluid" />
              <h5>Dependable</h5>
              <p>
                Our Custom solutions are dependable, in the rough terrain that
                is today's cyberspace. Rashail Tech Labs is built on a
                foundation of reliability and dependability.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <img src={facility} alt="Landingimg" className="img-fluid" />
              <h5>End-to-End Services</h5>
              <p>
                From initial concepts to drafting, implementation, and ongoing
                support, We offer end-to-end, complete service; as per client
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <img src={expertise} alt="Landingimg" className="img-fluid" />
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-md-10 text-center d-flex flex-wrap justify-content-around">
          <h4 className="mainheading mt-3 mb-3">
            Furthering
            <span className="mainheading-color"> Your Interests </span>{" "}
          </h4>
          <p className="paraheading mb-5 mt-3">
            Choosing us means gaining a trusted partner with proven expertise in
            IT consultancy. We bring tailored solutions that streamline your
            operations, enhance efficiency, and drive innovation.
          </p>

          <div className="  grid grid-cols-1  md:grid-cols-4 items-center justify-around gap-4">
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body ">
              <p class="card-text">Grow Business Revenues</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Stay ahead of your Rivals</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Gain loyal Customers</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Innovative Tech Support</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Cost-Effective Solutions</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Increase Sales and Lead Generation</p>
            </div>
          </div>
          <div
            class="card mb-3 service-card col-12 col-sm-6 col-md-4"
            style={{ width: "16rem" }}
          >
            <div class="card-body">
              <p class="card-text">Strategic IT Guidance</p>
            </div>
          </div>


          </div>
        </div>
      </div>
      <div className="row servicebottombackground mb-5 d-flex align-items-center">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h2 className="servicebottomheading mt-5">
            {" "}
            Change Begins here At Rashail Tech Labs.
          </h2>
          <p className="serviceparabottomheading mt-4 mb-5">
            "Let's Connect, Innovate, and Magnify Your IT Experience Together!"
          </p>
          <Link
            to="/contactus"
            style={{ color: "#7738F8", textDecoration: "none" }}
          >
            <button className="buttonfunctionaity mb-5">Get a Quote</button>
          </Link>
        </div>
      </div>

      <h4 className="mainheading text-center mb-5 mt-2">
          Our <span className="mainheading-color">Collaborative Ventures</span>{" "}
        </h4>
        <OurCollaborativeVentures/>
    </div>
  );
};

export default Services;
