import React from 'react';

const ProductFeatureCardGroup = () => {
  return (
    <div>
      <div className="d-flex flex-wrap justify-content-center mt-5 mb-5">
        <div className="col-md-4 mb-4">
        <div className="card portfoliodetailscard" style={{ height: "203px", width:'full' }}>




            <div className="card-body">
              <p className="duration-icon mt-4">&#9201;</p>
              <h5 className="card-title portfoliodetails-c-title">Duration</h5>
              <p className="card-text portfoliodetails-t-deatils">3 weeks, 5 days</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card portfoliodetailscard mx-2" style={{ height: "203px" }}>
            <div className="card-body">
              <p className="duration-icon mt-4">&#128279;</p>
              <h5 className="card-title portfoliodetails-c-title">Live Project link</h5>
              <p className="card-text portfoliodetails-t-deatils">www.rashailinfotech.co.in</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card portfoliodetailscard" style={{ height: "203px" }}>
            <div className="card-body">
              <p className="duration-icon mt-4">&#128241;</p>
              <h5 className="card-title portfoliodetails-c-title">Platforms</h5>
              <p className="card-text portfoliodetails-t-deatils">Web:Mobile,Tablet,Desktop</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatureCardGroup;
