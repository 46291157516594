import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Profoliodata } from "../Constant/data";
import OurCollaborativeVentures from "../Components/OurCollaborativeVentures";

const Portfolio = () => {


  return (
    <div className="container-fluid">
      <Helmet>

      <link rel="canonical" href="https://rashailtechlabs.co.in/portfolio" />

        <title>
          Portfolio - Showcasing Our Successful Projects and Client Success
          Stories
        </title>

        <meta
          name="description"
          title="Explore Our Portfolio of Successful Projects - Rashail Tech Lab"
          content="Dive into our portfolio to see the successful projects we've delivered and read client success stories showcasing how Rashail Tech Labs has helped businesses achieve their goals through innovative IoT & Automation Solutions."
        />
      </Helmet>

      <div className="row portfoliotopbackground">
        <div className="col-md-11 col-sm-12 text-center mt-5 mx-auto">
          <h1 className=" serviceheading mt-5 ">
            <span className="serviceheading-color">Our </span> Portfolio{" "}
          </h1>
          <h2 className="serviceparaheading mt-4 mb-5">
            Rashail Tech Labs has shared brighter days with a number of esteemed
            organizations.
          </h2>
        </div>
      </div>
      <div className="row">
        <h2 className="mt-5 mb-4 text-center mx-auto mainheading">
          A Showcase of Success:
          <span className="mainheading-color"> Our Dazzling Portfolio</span>
        </h2>
        <p className=" text-center mx-auto paraheading">
          Take a deep dive into creativity and digital excellence by exploring
          our glitzy portfolio
        </p>

        <div
          className="row justify-content-center align-items-center mt-4 mb-5"
    
        >
        <div className="row justify-content-center">
  {Profoliodata.map((project, index) => (
    <div key={index} className="col-md-3 mx-2 mt-5">
      <Link
        to={project.link}
        style={{ color: "#7738F8", textDecoration: "none" }}
      >
        <div className="card card-with-shadow-portfolio">
          <img
            src={project.projectimage}
            className="card-img-top img-fluid"
            alt="..."
            style={{
              width: "100%",
              height: "350px",
              padding: "40px",
            }}
          />
          <div
            className="card-body portfolio-fixed-card-body"
            style={{
              overflow: "hidden",
              width: "100%",
              height: "110px",
            }}
          >

<div className=" text-sm flex flex-col items-center
            ">
            <h5 className="card-title mt-2 mb-2  text-2xl">
              {project.title}
            </h5>
          
            <p className=" text-base text-blue-500 mx-auto"
            style={{padding:"4px"}}>{project.sticker_text}</p>
            </div>
            {/* <div className="readmore-portfolio">Read More</div> */}
          </div>
        </div>
      </Link>
    </div>
  ))}
</div>

        </div>
      </div>
      <div className="row teamfoliobackground d-flex justify-content-center align-items-center">
        <div className="col-md-6 text-start">
          <h4 className="teamupportfolio">
            Have Any Query? Don’t Hesitate!
            <br />
            Give Us A Shout!.
          </h4>
        </div>
        <div className="col-md-4">
          <Link to="/contactus">
            <button className=" btn buttonfunctionaity">Get a Quote →</button>
          </Link>
        </div>
      </div>

      <h4 className="mainheading text-center mb-5 mt-3">
          Our <span className="mainheading-color">Collaborative Ventures</span>{" "}
        </h4>

    <OurCollaborativeVentures/>
    </div>
  );
};

export default Portfolio;
