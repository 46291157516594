
import card1 from "../Images/Service/Group 1686553772.png";
import card2 from "../Images/Service/Group 1686553769.png";
import card3 from "../Images/Service/Group 1686553774.png";
import card4 from "../Images/Service/Group 1686553773.png";
import card5 from "../Images/Service/Group 1686553770.png";
import card6 from "../Images/Service/Group 1686553766.png";
import card7 from "../Images/Service/Group 1686553764.png";
import card8 from "../Images/Service/Group 1686553771.png";
import card9 from "../Images/Service/Group 1686553775.png";
import card10 from "../Images/Service/Group 1686553776.png"

export const cardData = [
    {
      "id": 1,
      "title": "Comprehensive Electronic Circuit Design services",
      "image": card1,
      "description": "From concept to launch, we handle PCB design, testing, firmware, compliance, and everything in between!"
    },
    {
      "id": 2,
      "title": "Complete Product Prototyping & Development services",
      "image": card2,
      "description": "From initial concept to final product, we cover prototyping, testing, design iterations, manufacturing liaison, and launch support."
    },
    {
      "id": 3,
      "title": "Electronics Automation",
      "image": card3,
      "description": "Covering everything from design to implementation, including automation testing, system integration, firmware development, and more!"
    },
    {
      "id": 4,
      "title": "Cloud Integration",
      "image": card4,
      "description": "Connect your systems effortlessly with data migration, API integration, security, scalability, and ongoing support."
    },
    {
      "id": 5,
      "title": "Hardware Integration",
      "image": card5,
      "description": "Bridging components, systems, and functions for optimal performance and reliability."
    },
    {
      "id": 6,
      "title": "Healthcare Device Automation",
      "image": card6,
      "description": "From design to deployment, we automate processes, ensure compliance, and optimize performance for your medical devices."
    },
    {
      "id": 7,
      "title": "Research and Development",
      "image": card7,
      "description": "Complete Research and Development solutions: We cover ideation, prototyping, testing, market analysis, and innovation for your next breakthrough!"
    },
    {
      "id": 8,
      "title": "Engineering Services",
      "image": card8,
      "description": "End-to-end Engineering Services: From design to deployment, we cover PCB, circuit simulation, firmware, compliance, testing, and more!"
    },
    {
      "id": 9,
      "title": "AI & ML",
      "image": card9,
      "description": "From data preprocessing to model deployment, we offer services in algorithm development, data labeling, model training, optimization, validation, deployment, and ongoing support."
    },
    // {
    //   "id": 10,
    //   "title": "IoT and Digital Engineering",
    //   "image": card10,
    //   "description": "We cover hardware, firmware, connectivity, data analytics, security, and end-to-end product development for your smart technology needs."
    // }
  ]
  