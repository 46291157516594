import React from "react";
import img1 from "../Images/ResearchandDev/8392607_3905271 1.png";
import img2 from "../Images/ResearchandDev/8392585_3901621 1.png";
import img3 from "../Images/ResearchandDev/Group 1686553789.png";

const ResearchandDev = ({ techdata }) => {
  const {
    timeline: { title, paragraph: researchParagraph },
    prototype,
    manufacturing,
  } = techdata;

  return (
    <div className="mt-28 px-16 md:px-72 bg-[#EDF8FF]">
      <div className="flex flex-col gap-10 border-l-4 pl-16 relative">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex flex-col items-start gap-10 md:w-80">
            <div className="flex flex-row items-center justify-center relative">
              <img src={img3} alt=" " className="absolute left-[-75px] w-12" />
              <h2 className="text-[#1388CD] text-3xl font-semibold">{title}</h2>
            </div>
            <p>{researchParagraph}</p>
          </div>
          <img src={img1} alt="" className="h-80" />
        </div>

        <div className="flex flex-col items-start justify-around">
          <div className="flex flex-row items-center justify-center">
            <img src={img3} alt=" " className="absolute left-[-15px] w-12" />
            <h2 className="text-[#1388CD] text-3xl font-semibold">Prototype</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
  {prototype.map((description, index) => (
    <div key={index} className="flex flex-row mt-4">
      <div className="text-5xl text-slate-400 font-semibold mx-4">{index + 1}</div>
      <p className="h-auto w-72 overflow-hidden mx-auto">{description}</p>
    </div>
  ))}
</div>

        </div>

        <div className="flex flex-col items-start gap-8">
          <div className="flex flex-row items-center justify-center">
            <img src={img3} alt=" " className="absolute left-[-15px] w-12" />
            <h2 className="text-[#1388CD] text-3xl font-semibold">Manufacturing</h2>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-around w-full gap-4">
            <img src={img2} alt="" className="h-80" />
            <p className="w-72">{manufacturing}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchandDev;
