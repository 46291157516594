import React, { useRef } from "react";
import { IoStar } from "react-icons/io5";
import Slider from "react-slick";

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  arrows: false,
};

const testimonials = [
  {
    name: "A R Parmar",
    rating: 5,
    text: "They are really having good products. One of their products, GSM motor controller, is of great use for farmers like us.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUcMsHrCcwjFmYxDabWR5RlvmuahozDdwmy1nLiOuJ9t6-tEwKKOg=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Chandani Agrawal",
    rating: 5,
    text: "Gained conceptual knowledge. Very interactive environment. And friendly mentors. I'm grateful for the guidance.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWB4JpwHRNxKEG-x40DcjRprrSCfHI14JTQ3T0q5YYAnyi3985r=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Anand kumar",
    rating: 5,
    text: "Amazing company product. GSM device is working amazingly well. This product is so nice.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUcMsHrCcwjFmYxDabWR5RlvmuahozDdwmy1nLiOuJ9t6-tEwKKOg=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Jayprakash Gupta",
    rating: 5,
    text: "This device helps me a lot to keep my home solution on the latest generation with automation. Thanks a lot.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWKNq2s-kgSZPi_SoRIwplUpPNBmUipXfVPDgOTImQz8YFII3gnPA=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Sanil Nayak",
    rating: 5,
    text: "GSM motor starter controller is the best product of this company.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWB4JpwHRNxKEG-x40DcjRprrSCfHI14JTQ3T0q5YYAnyi3985r=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Ansh Pathak",
    rating: 5,
    text: "Very nice products by Rashail Tech Labs.",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUcMsHrCcwjFmYxDabWR5RlvmuahozDdwmy1nLiOuJ9t6-tEwKKOg=w75-h75-p-rp-mo-br100",
  },
  {
    name: "BL Gaming",
    rating: 5,
    text: "Super fully automatic products by Rashail Tech 👍👍👍",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWKNq2s-kgSZPi_SoRIwplUpPNBmUipXfVPDgOTImQz8YFII3gnPA=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Sonu Parmar",
    rating: 5,
    text: "The people here are full of enthusiasm and innovative ideas.... Who strive to build great products at cheap prices and are customer-centric.",
    iamge:
      "https://lh3.googleusercontent.com/a-/ALV-UjUcMsHrCcwjFmYxDabWR5RlvmuahozDdwmy1nLiOuJ9t6-tEwKKOg=w75-h75-p-rp-mo-br100",
  },
];

const Testimonials = () => {
  const sliderRef = useRef(null);

  return (
    <div className="row mt-md-5 client-feedback-mobile justify-content-center w-full">
      <h2 className="mainheading text-center">
        What <span className="mainheading-color">Our Clients</span> Say
      </h2>

      <div className="col-md-10 text-center  ">
        <div className="testimonial-slider-container ">
          <Slider ref={sliderRef} {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-slide" key={index}>
                <div className=" mx-2">

                
                <div
                  className="card card-slider-home-client-c  m-3 mt-5  "
                  style={{ backgroundColor: "white", height: "180px"   }}
                >
                  <div className="card-body">
                    <div className="testimonial-content">
                      <div className="testimonial-image d-flex">
                        <img
                          src={testimonial.image}
                          alt={testimonial.name + "'s Image"}
                          className="img-fluid rounded-circle blue-border"
                        />
                        <div className="testimonial-text mx-2">
                          <h4 className="slidercardheading text-start">
                            {testimonial.name}
                          </h4>
                          <div className=" flex flex-row gap-1 text-orange-400">
                            {[...Array(testimonial.rating)].map(
                              (star, index) => (
                                <IoStar
                                  key={index}
                                  className=" flex"
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="slidercard " style={{ margin: "15px" }}>
                        {testimonial.text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
