import product1 from "../Images/Portfolio/product1.png";
import bg1 from "../Images/Portfolio/1st headerBg.png";
import bg2 from "../Images/Portfolio/Headerbackground2.png";
import bg3 from "../Images/Portfolio/HeaderBg3.png";
import bg4 from "../Images/Portfolio/HeaderBg4.png";
import bg5 from "../Images/Portfolio/HeaderBg 5.png";
import sticker1 from "../Images/Portfolio/sticker1.png";
import stickerFood from '../Images/Portfolio/food_sticker.jpg'
import sticker4 from "../Images/Portfolio/sticker4.png";
import sticker5 from "../Images/Portfolio/sticker5.png";
import sticker6 from "../Images/Portfolio/sticker6.png";
import product2 from "../Images/Portfolio/product2.png";
import product3 from "../Images/Portfolio/product3.png";
import product4 from "../Images/Portfolio/product4.png";
import product5 from "../Images/Portfolio/product5.png";
import product6 from "../Images/Portfolio/product6.png";
import product7 from "../Images/Portfolio/product7.png";
import cardIcon1 from "../Images/Portfolio/cardIcon1.png";
import cardIcon2 from "../Images/Portfolio/cardIcon2.png";
import cardIcon3 from "../Images/Portfolio/cardIcon3.png";
import cardIcon4 from "../Images/Portfolio/cardIcon4.png";


export const Profoliodata = [
  {
    title: "UV Phototherapy Booth",
    projectimage: product1,
    background: bg1,
    sticker: sticker1,
    sticker_text: "HealthCare",
    link: "uv-phototherapy-booth",
    subheading:
      "Developing a System for the Treatment of Vitiligo with UV Phototherapy and Remote Monitoring.",

    Helmettitle:
      "UV Phototherapy Booth - Innovative Solution for Skin Treatment",
    metaTitle: "Explore Our UV Phototherapy Booth - Rashail Tech Labs",
    metaDescription:
      "Presenting our state-of-the-art UV Phototherapy Booth, a revolutionary solution to treating a wide array of skin conditions effectively and safely. Find out how Rashail Tech Labs is disrupting the skincare industry and the treatment received by patients.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Supreme Engineering",
        description:
          "Fabricated a system for the treatment of Vitiligo with UV Phototherapy.",
        styleColor: "#7CC0A8",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "IoT Technology",
        description: "Uses IoT technology for remote operations.",
        styleColor: "#999EC9",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Centralised Web Dashboard",
        description:
          "Centralized dashboard for controlling and monitoring the Phototherapy Booth.",
        styleColor: "#E2DCA8",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "Integrated with Mobile App",
        description: "Custom mobile application for further ease of use.",
        styleColor: "#D79E9D",
      },
    ],
    project_challenges:
      "As with any healthcare product, we needed to make sure of its efficacy and account for possible side effects very early on in the process. Phototherapy is a special light therapy prescribed to treat skin disorders using ultraviolet (UV) light; achieving perfection in this treatment and matching Rashail Tech Labs' precision is a challenge.",

    tech: {
      timeline: {
        title: "Research & Development",
        paragraph:
          "We experimented with different wavelengths before finalizing narrow-band UVB (nbUVB), for its high success rate in treating vitiligo.",
      },
      prototype: [
        "Our first Prototype was crudely constructed to match the specifications of our client.",
        "We made sure of our product's efficacy and ironed out the kinks, implementing crucial improvements along the way.",
        "We tested our nearly finished product in real-life scenarios and calibrated it accordingly.",
      ],
      manufacturing:
        "Precision-engineered product, finally ready for mass manufacturing.",
    },
    hardware: {
      points:
        "Adopting the industry-standard Holand Phillip UV lamp for stability and increased life.\n40 lamps, each 100W, UVA, and UVB can be used independently or in conjunction.\nThe reflector board is installed in the interior and improves the radiation efficiency.\nPre-installed dual monitoring system ensures patient safety.\nAnti-jamming technology to shield against strong magnetic fields and operate the equipment normally.\nHigh-intensity radiation output, shortened the treatment time, for a high cure rate.\nScientific ventilation and cooling system, for extensive usage.",
      technologies_used:
        "Precision UV Sensors, Touch Display, OTA Updates, WiFi based IoT Connectivity ",
    },
    software: {
      points:
        "Centralized Web Dashboard, for remotely accessing your UV Phototherapy records and controlling all IoT Photobooths.\nMobile App functionality for further ease of use and real-time visibility into various aspects of UV Photobooth's operations.",
      technologies_used: "Python-Django, Flutter, React JS, AWS IoT Core",
    },
    contribution:
      "Developed an IoT Enabled Phototherapy Booth for Vitiligo Treatment; along with a Centralized Web Dashboard, Mobile App to Control and Monitor all IoT Photobooths.\n Currently impacting the lives of those with Vitiligo disease, and the ones who have been cured by our creation",
  },

  {
    title: "IoT Solar Dryer",
    projectimage: product2,
    background: bg2,
    sticker: stickerFood,
    sticker_text: "Agriculture",
    link: "iot-solar-dryer",
    subheading:
      "Develop Centralized Systems to gather and analyze Atmospheric Data within a Solar Dryer Unit for different commodities and units. This includes IoT Data Loggers for Solar Dryer Units, a Centralized Web Dashboard, Mobile App.",
    Helmettitle: "IoT Solar Dryer - Sustainable Drying Solutions",
    metaTitle: "Explore Our IoT Solar Dryer Project - Rashail Tech Labs",
    metaDescription:
      "Introducing our IoT Solar Dryer project, a sustainable and practical combination of IoT technology and solar drying that increases efficiency and reduces the carbon footprint. Rashail Tech Labs is enabling a healthier, greener, and more sustainable future with its bold advancements in agricultural technology.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Intricate Product Development",
        description:
          "Engineered a bite-sized Data logger to analyze atmospheric parameters within IoT Solar Dryers.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "AI/ML Models for Research",
        description: "AI and Machine Learning based modeling and research.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Solar Powered",
        description: "Generates enough energy to power the dryer for free.",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "Armour-Grade Durability",
        description:
          "All-weather device, to stand strong against all elements of nature.",
      },
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "IoT Enabled",
        description: "Uses IoT technology for remote operations.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Centralised Web Dashboard",
        description:
          "Dashboard for controlling and monitoring IoT Solar Dryer units.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Integrated with Mobile App",
        description: "Custom mobile application for further ease of use.",
      },
    ],
    project_challenges:
      "Solar Dryers are notorious for damaging food, as a product can be dried too quickly, hardening it; or at too high a temperature, resulting in an inferior quality product. We set out to make a reliable product, that provides consistent heat, regardless of day temperature.",
    tech: {
      timeline: {
        title: "Research & Development",
        paragraph:
          "We experimented with the most efficient ways to eliminate moisture from crops, vegetables, and fruits- Insulation, Single or Double-Glazed glass, etc.",
      },
      prototype: [
        "In the first phase, we worked with the principle of density differential, to maintain optimum drying conditions.",
        "Based on our readings, we re-fabricated the device for maximum efficiency and continuous drying operation.",
        "Putting our smart device to a stringent of tests in a controlled environment and making performance-based adjustments.",
      ],
      manufacturing:
        "Finished product pushed to mass manufacturing at the behest of our client.",
    },
    hardware: {
      points:
        "2 MS net tray with toughened glass\n2 Solar Fans\nUniform solar drying of products.\nSuitability for rural/remote areas.\nLightweight and easy handling.\nPaper sheet Reflector.\nOption to dry products in the shade.\nDrying temperature in the desirable range.\nMaximum Temperature 65 to 70°",
      technologies_used: "Temp. & Humidity Sensor, Relay & Sensor Hub, GPRS based IoT Connectivity, MQTT Protocol Communication",
    },
    software: {
      points:
        "Custom software to compile all data received from sensors and input into machine learning models, along with artificial intelligence to fine-tune results. An intuitive web dashboard to control and monitor IoT Enabled Smart Dryers.",
      technologies_used:
        "Python-Django, React JS, Flutter, AWS IoT Core, CLoud Analytics",
    },
    contribution:
      "Our client, primarily dealing in manual Solar Dryer Units and exporting dried products to national & international markets; required a well-engineered IoT Solar Dryer; superior to current industry standards. Rashail Tech Labs's finished product was on par with client expectations and excelled in all trials it was put through. Now ever-present in the market, matching the quality associated with our client's brand.",
  },

  {
    title: "Fleet Management System",
    projectimage: product3,
    background: bg3,
    sticker: sticker4,
    sticker_text: "Automobile Manufacturing",
    link: "fleet-management-system",
    Subheading:
      "Designing a Fleet Management System for buses and trucks with 13 Parameters for vehicle health and tracking; along with a Centralised dashboard and Mobile functionality.",

    Helmettitle: "Fleet Management System - Optimizing Vehicle Operations",
    metaTitle:
      "Explore Our Fleet Management System Project - Rashail Tech Labs",
    metaDescription:
      "Discover our Fleet Management System project, designed to streamline vehicle operations, improve efficiency, and enhance safety. Learn how Rashail Tech Labs leverages technology to empower businesses with comprehensive fleet management solutions.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Fleet Management System",
        description:
          "Advanced FMS to track transportation, improve asset visibility, and monitor maintenance, utilization and costs.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Fuel Monitoring",
        description:
          "Monitors fuel on board and alerts the user in case of any discrepancy.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Built-in GPS",
        description:
          "Utilizes GPS to plot vehicle locations and keep track of our assets.",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "Wifi Calling",
        description:
          "Makes calls over wifi, across India and abroad, with a crystal clear voice.",
      },
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Driver Messaging",
        description:
          "Allows for two-way communication between the fleet manager and driver.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Centralised Web Dashboard",
        description: "Dashboard for controlling and monitoring our FMS.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Integrated with Mobile App",
        description: "Custom mobile application for further ease of use.",
      },
    ],
    project_challenges:
      "Incorporating 13 highly complex features in a singular tool is easier said than done. Precision has to be a prerequisite to ensure driver safety and keep the company's assets productive. Additionally, we wanted our feature-rich software to be as fluid and as easy to use as possible. Distraction-free for the driver and highly resourceful for the fleet manager",

      
    tech: {
      timeline:
        {
          title: "Planning Stage",
          paragraph:
            "Research and requirement analysis to make sure we had a clear idea of our deliverables and possible risks. This includes the prototype drafts, market research, evaluation of competition, Software Requirement Specification (SRS) document, and a suitable hardware configuration to make sure everything works as it should.",
        },
      prototype:[
      
  
            "In the first phase, we sketched the following- Interface, Databases, Core Features & Architecture, User Interface, Network, and its requirements. Extensive front and back-end development using multiple tools, programming environments, and languages; in-line with the SRS document.",
    

            "Detecting and fixing possible bugs, defects, errors, vulnerabilities, etc, making sure our Fleet Management System, and it's associated hardware works correctly, and coherently with client objectives and expectations.",
      
   
            "Here we put our FMS to the test; deploying it in a pre-prod environment and putting contingencies in place to mitigate any unforeseen outcomes. As a large system, we were prepared for possible difficulties, if they may arise and reacted to the reported issues in a timely manner.",

          
      ],
      
  
      manufacturing: "Our Fleet Management System and its associated tech, rolled out for use with flying colors. It now requires minimal maintenance and few OTA updates from time to time to stay updated with the latest technologies, a win huge-win for our client in terms of numbers and value addition to the firm."
      

    },

    hardware: {
      points: "Sound System \n Fuel monitoring \n Tyre Pressure Monitoring System (TPMS) \nRGB Display Module \n P10 Display Modules \n Fire alarm sensor \n Geo-Fencing \nWiFi Cameras",

      technologies_used:"Automobile Sensors, GPRS based IoT Modules, MQTT Protocol for Communication"
    },


    software:{
      points:"Our Fleet Management Software went through 7 stages of the software development life cycle (SDLC)Planning, Feasibility or Requirements of Analysis Stage, Design and Prototyping, Software Development, Software Testing, Implementation and Integration, and then finally the Operations and Maintenance Stage. \n Addressing the safety issues that come coupled with this sensitive sector, we tested our FMS extensively and put contingencies in place to mitigate any unforeseen outcomes.",

      technologies_used:"Python-Django, FLutter, ReactJS, Cloud Analytics, Google Map APIs, AWS IoT Core"
    },
    contribution:
      "Rashail Tech Lab's Fleet Management System rivals anything on the market today; tracking, monitoring, and managing our client's commercial vehicles- Buses, trucks, and company-assigned vehicles with precision.",
  },

  {
    title: "Health ATM Kiosk",
    projectimage: product4,
    background: bg1,
    sticker: sticker1,
    sticker_text: "HealthCare",
    link: "health-atm-kiosk",
    subheading:
      "Developing communication modules for the Government of India's Health ATM Kiosk Unit and SaaS platform; integrating Glucometer, HemoCue WBC analyzer, HemoCue RBC Analyser, Weight Machine, and others.",
    Helmettitle: "Health ATM Kiosk - Convenient Healthcare Access",
    metaTitle: "Explore Our Health ATM Kiosk Project - Rashail Tech Labs",
    metaDescription:
      "Discover our Health ATM Kiosk project, offering convenient and accessible healthcare services. Learn how Rashail Tech Labs combines technology and healthcare expertise to bring essential medical services closer to communities, improving healthcare accessibility and convenience.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Health ATM/Smart\n Health Kiosk Machine",
        description:
          "A touch-screen-based, ATM-sized machine fitted with cutting-edge diagnostic equipment for screening vital health parameters.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Smart Sensor Hub",
        description:
          "Integrating different communication and health measurement modules in a singular hub.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "High-Tech Sensors",
        description: "Sensing technologies to detect vital health information.",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "Communication Modules",
        description:
          "Modular architecture, for better functionality and ultraprecision.",
      },
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Integrated with SaaS Platform",
        description:
          "Seamlessly integrated with the SaaS platform connecting Doctors & Patients.",
      },
    ],
    project_challenges:
      "Working with the government to manufacture low-cost Health ATM Kiosks; with cutting-edge technologies, in a limited time and budget.",

    tech: {
      timeline: {
        title: "Research & Development",
        paragraph:
          "Our R&D department worked extensively on product development, incorporating- a Glucometer, HemoCue WBC analyzer, HemoCue RBC Analyser, Wireless health measuring, etc; and engineering a market-defining, medical device.",
      },

      prototype: [
        "Our first prototype only had fundamental elements, with a basic structure, detecting certain health conditions.",
        "Moving forward, our product was functionally ready, and already a highly-capable machine; it underwent comprehensive testing in real-life scenarios.",
        "Based on the data received, we calibrated our health ATM to precision and made improvements related to service life.",
      ],
      manufacturing:
        "Our final iteration, which was deemed production-quality was pushed to mass manufacturing.",
    },
    hardware: {
      points:
        "Glucometer\nHemoCue WBC Analyzer\nHemoCue RBC Analyser\nWeight Machine\nWireless Height Measurement\nHD Camera\nHD Touch Display\nSlots for Medical Equipment\nReceipt/Report Slip Printing",
      technologies_used: "Automobile Sensors, GPRS based IoT Modules, MQTT Protocol for Communication",
    },
    software: {
      points:
        "Bespoke software to integrate with our Smart Sensor Hub, and work seamlessly with the SaaS platform to connect doctors and patients. We also integrated communication modules and Precise Height Measurement Modules with the health ATM.",
      technologies_used: "-Python-Django, FLutter, ReactJS, Cloud Analytics, Google Map APIs, AWS IoT Core",
    },
    contribution:
      "Our Health ATM is now primarily serving rural areas of India, where medical care isn't accessible to the masses. Bridging the gap between urban and rural healthcare with technology.",
  },

  {
    title: "Biometric Door Access System",
    projectimage: product5,
    background: bg3,
    sticker: sticker6,
    sticker_text: "Industrial Automation",
    link: "biometric-door-access-system",
    subheading:
      "Constructing a custom Biometric Door Access System for a secure facility in Dewas.",

    Helmettitle: "Biometric Door Access System - Secure Access Solutions",
    metaTitle:
      "Explore Our Biometric Door Access System Project - Rashail Tech Labs",
    metaDescription:
      "Discover our Biometric Door Access System project, providing secure and efficient access control solutions. Learn how Rashail Tech Labs uses biometric technology to enhance security measures and streamline access management for businesses and organizations.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Access Control Terminal",
        description:
          "Secure, using advanced algorithms for identification and authentication.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Biometric Fingerprint Reader",
        description: "Excellent reliability, precision, and matching speed.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Keypad Remote Control",
        description: "Easy to arm/disarm and more custom",
      },
    ],
    project_challenges:
      "Military Grade, Biometric Security system requires complex engineering to be secure and indestructible; requiring significant amounts of R&D for effective use.",
    tech: {
      timeline:
        {
          title: "Research & Development",
          paragraph:
            "We adopted high-end biometrics technology for its trustworthiness and comfortable usage. Our custom software, to accompany this fancy hardware, underwent penetration tests from the inception, for its ability to withstand decryption and deny vulnerability.",
        },
       prototype:[
            "Starting with fabricating the hardware, we used hardened steel as the backbone of our secure system.",
   
            "By integrating the custom software with our distinguished hardware, our prototype was ready to undergo testing in real-world scenarios.",
       
            "During our trials, we incorporated multiple redundancies/fail-safes, to unlock/lock in case of unforeseen circumstances.",
],
        manufacturing:
      
            "Once we were satisfied with our offerings, we went ahead to push the final form of our Biometric Door Access System to manufacture in greater numbers."
    },
    hardware: {
      points:
        "Fingerprint and Card based\nTamper Detection\nWater Resistance for Outdoor Usage\nMilitary-Grade Durability but Slim and Elegant design\nEase of Installation and Connectivity\nEasy to Scale up when required",
      technologies_used:
        "Fingerprint Sensor, WiFi Based IoT Controller, Electro-magnetic Door Locks, OTA Updates, Keypad with Display",
    },
    software: {
      points:
        "Custom, secure software to enlist, manage authentications, oversee log-in/out of the workforce, and generate entry reports.",
      technologies_used:
        "Python-Django, AWS IoT Core, React JS",
    },
    contribution:
      "Coupled with our Hardware + Software solution, this next level of biometric-integrated access system is secure, dependable, and suits its final destination at a secure, pharmaceutical facility in Dewas, Indore.",
  },

  {
    title: "IoT Smart Street Lights",
    projectimage: product6,
    background: bg4,
    sticker: sticker6,
    sticker_text: "Smart Lighting Solutions",
    link: "iot-smart-street-lights",
    subheading:
      "Develop Smart, IoT Lighting Solutions for IIT Indore, SGSITS Indore & Different Government Bodies (Class A Contractor).",

    Helmettitle:
      "IoT Smart Street Lights - Intelligent Urban Lighting Solutions",
    metaTitle:
      "Explore Our IoT Smart Street Lights Project - Rashail Tech Labs",
    metaDescription:
      "Discover our IoT Smart Street Lights project, revolutionizing urban lighting with intelligent and energy-efficient solutions. Learn how Rashail Tech Labs is transforming cities with smart technologies that enhance safety, reduce energy consumption, and improve sustainability.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "IoT Enabled Street Lights",
        description:
          "Internetworked IoT lighting systems, making next-gen public lighting management possible.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Automated Street Light Controller",
        description:
          "Light controller coupled with Timers, scheduling options.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Photogenic Sensors",
        description:
          "High-tech sensors detecting the presence of surrounding light",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "Centralized Control Panel",
        description:
          "Centralized dashboard for remotely controlling and monitoring smart lighting solutions.",
      },
    ],
    project_challenges:
      "Making efficient public lighting solutions that consume as little energy as possible, for a fraction of the cost offered by other Class A contractors.",
    tech: {
      timeline:
        {
          title: "Research & Development",
          paragraph:
            "Our R&D team worked on making the system as efficient and lean as possible, to enable long-term energy savings; this included light diodes, wiring harness, and light-weight software.",
        },
     prototype:[
            "Initially, we went for a crude assembly of components, working on consolidation and efficiency. Our first prototype was inherently basic, barebones of the larger smart lighting system.",
   
            "Next, we calibrated our Photogenic Sensors, illumination systems to suit the Indore locale; coupling it with our specialized hardware configuration for cost-effectiveness.",
   
            "Our IoT smart street lights prototype underwent a field test, in real-world conditions. Here we measured energy consumption using sophisticated instruments and made modifications to minimize it.",
        ],
        
     manufacturing:
              "After comprehensive testing and our client being satisfied with our finished product, we moved to our next step-manufacturing at scale."
    },
    hardware: {
      points:
        "Wireless Network: Sub-G with Gateway, NB-IoT/ LoRa\n Lightning Controller\n Data Center: The Cloud, User's Server\n Centralized Control Panel\n Automated Street light controller system\n Photogenic Sensors",
      technologies_used: "Photo Sensor, GPRS Based IoT Module, MQTT Protocol for Communication",
    },
    software: {
      points:
        "Embedded software that operates together with the hardware to control and remotely monitor luminaires for efficiency.",
      technologies_used:
        "Python-Django, Cloud Analytics",
    },
    contribution:
      "Smart Street Light Control Units and IoT Enabled Street Light Control Units are currently Installed at IIT Indore and SGSITS Indore.",
  },

  {
    title: "Smart AgriTech Solutions",
    projectimage: product7,
    background: bg5,
    sticker: sticker5,
    sticker_text: "Smart Agriculture Equipment",
    link: "smart-agritech-solutions",
    subheading:
      "Build a Weather Station to monitor Micro-climatic Conditions of the farm, and provide real-time analytical values to the farmers for their crop and soil health.",

    Helmettitle:
      "Smart AgriTech Solutions - Innovating Agriculture with Technology",
    metaTitle:
      "Explore Our Smart AgriTech Solutions Project - Rashail Tech Labs",
    metaDescription:
      "Discover our Smart AgriTech Solutions project, leveraging advanced technology to revolutionize agriculture. Learn how Rashail Tech Labs is empowering farmers with smart farming solutions, optimizing crop yield, reducing resource usage, and promoting sustainable agricultural practices.",
    cards_section: [
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Micro-Climatic Monitoring",
        description:
          "Farm-based, micro-level reporting- Rain probability, Humidity, Wind Speed, Cloud cover, and so much more.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "In-Built Data Logger",
        description:
          "Monitors and records environmental parameters for measuring, documenting, analyzing, and validating.",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "Communication Array",
        description: "Transmitting with wireless 3G and WiFi communication.",
      },
      {
        icon: cardIcon4,
        color: "#D79E9D",
        title: "High-tech Sensors",
        description:
          "Individual Sensors measure- Solar Radiation, Wind Speed & Direction, Air Temperature & Humidity.",
      },
      {
        icon: cardIcon1,
        color: "#7CC0A8",
        title: "Surveying Equipment",
        description: "Measures liquid precipitation (rain) via a Rain gauge.",
      },
      {
        icon: cardIcon2,
        color: "#999EC9",
        title: "Solar Powered",
        description: "Energy is generated by a photovoltaic module",
      },
      {
        icon: cardIcon3,
        color: "#E2DCA8",
        title: "High-Grade Steel",
        description:
          "Built to withstand the harshest of conditions known to man",
      },
    ],
    project_challenges:
      "Constructing a well-engineered, mass-market product that is ultraprecise in every weather condition.",
    tech: {
      timeline: 
        {
          title: "Research & Development",
          paragraph:
            "Our team worked extensively on the sensing technologies, lab-testing them in simulated conditions. Our centralized unit and data logger for real-time analytics underwent calibration as well for data accuracy in all-weather conditions.",
        },
      prototype: [
            "Our hardware fabrication in the first prototype stage took center stage, we wanted a structurally sound and agile system, capable of hosting our sophisticated instruments, equipment, and much more.",
     
            "We coupled our sensors, surveying equipment, data logger, communication array, and other instrumentation with our hardware.",
    
            "Our weather station underwent a multitude of assessments for precision and capabilities.",
      ],
        manufacturing:
      
            "Finally, after all the tweaks and configurations were done, we pushed our final prototype to mass- production.",
    },
    hardware: {
      points:
        "Solar Panels\n Data logger\n Communications Array\n Wind Speed and Direction Sensor\n Air Temperature and Relative Humidity Sensor\n Solar Radiation Sensor\n Rain Gauge\n Mounting Tripod",
      technologies_used: "Sensors for Precision Farming (Analog & Digital), GPRS based IoT Module, Relay Hub, MQTT Protocol for Communcation",
    },
    software: {
      points:
        "We coupled our exceptional farm weather station with an equally perfect custom software solution. Accurately analyzing data from high-end sensors, to provide Micro-Climatic insights responsible for crop and soil health.",
      technologies_used: "Python-Django, Satellite Imaging, Cloud Analytics, AI/ML, AWS IoT Core, Flutter",
    },
    contribution:
      "Our gleaming farm weather station now flags our client's brand and is sold in large numbers to customers around the globe.",
  },
];
