import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../Images/Navbar/techlab-logo.png";
import { Link, useLocation } from "react-router-dom";
import "../Css/Home.css";
import filler1 from "../Images/Fillers/t shaped l shaped block of cubes-1.png";

function Navbar() {
  const location = useLocation();
  const isRouteActive = (path) => {
    return location.pathname === path;
  };

  // Apply the gradient-text class conditionally based on the current route
  const getLinkClass = (path) => {
    return isRouteActive(path)
      ? " gradient-text-navbar"
      : "nav-link font-weight-bold";
  };

  const handleLinkClick = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler) {
      navbarToggler.click(); // Simulate a click on the navbar toggler
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top text">
      <div className="container-fluid">
        {/* Logo Image */}
        <Link className="navbar-brand mx-5 py-2" to="/">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "90px", height: "40px" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div
            className="filler1"
            style={{
              position: "absolute",
              top: 2,
              right: 660,
              width: "100px",
              height: "100px",
              backgroundImage: `url(${filler1})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: -2,
            }}
          ></div>
          <div className="navbar-nav ms-auto">
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/")}
              to="/"
            >
              Home
            </Link>
            <span style={{ margin: "0 10px" }}></span>{" "}
            {/* Add a separator (e.g., a vertical bar) with some margin */}
            {/* <Link className="nav-link  font-weight-bold " to="/aboutus">About Us</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/aboutus")}
              to="/aboutus"
            >
              About Us
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link font-weight-bold" to="/services">Services</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/services")}
              to="/services"
            >
              Services
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link  font-weight-bold" to="/portfolio">Portfolio</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/portfolio")}
              to="/portfolio"
            >
              Portfolio
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link  font-weight-bold" to="#">Contact Us</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/contactus")}
              to="/contactus"
            >
              Contact Us
            </Link>



            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/case-studies")}
              to="/case-studies"
            >
              Case Studies
            </Link>
            <span style={{ margin: "0 40px" }}></span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
