import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./Components/Footer";
import Aboutus from "./Pages/Aboutus";
import ScrollToTop from "./Components/scroll";
import Contactus from "./Pages/Contactus";
import Services from "./Pages/Services";
import Portfolio from "./Pages/Portfolio";
import Career from "./Pages/Career";
import Privacypolicy from "./Pages/Privacypolicy";
import Termscondition from "./Pages/Termscondition";
import UVPHOTOtherapy from "./Pages/UVPHOTOtherapy";
import { Profoliodata } from "./Constant/data";
import ResearchandDev from './Components/ResearchandDev';
import PortfolioHomecare from "./Pages/Homecareportfolio";
import CaseStudies from "./Pages/CaseStudies";
import Data from "./Constant/CaseStudyData";





function App() {

const dynamicRoutes =Profoliodata.map((route, index) => (
  <Route key={index} exact path={`/portfolio/${route.link}`}  element={<UVPHOTOtherapy/>} />
));



const dynamicRoutesCase = Data.map((route, index) => (
  <Route key={index} exact path={`/case-studies/${route.link}`}  element={<CaseStudies/>} />
));
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/aboutus" element={<Aboutus />} />
        <Route exact path="/contactus" element={<Contactus />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/portfolio" element={<Portfolio />} />

        <Route exact path="/case-studies" element={<CaseStudies/>} />

        {dynamicRoutes}

        {dynamicRoutesCase}
   
        {/* <Route exact path="/portfolio/:IoTSolarDryerPortfolio" element={<IoTSolarDryerPortfolio />} />
        <Route exact path="/portfolio/FleetManagementSystem" element={< FleetManagementSystem />} />
        <Route exact path="/portfolio/HealthATMKiosk" element={<HealthATMKiosk />} />
        <Route exact path="/portfolio/BiometricDoorAccessSystem" element={<BiometricDoorAccessSystem />} />
        <Route exact path="/portfolio/IoTSmartStreetLights" element={<IoTSmartStreetLights/>} />
        <Route exact path="/portfolio/SmartAgriTechSolutions" element={<SmartAgriTechSolutions />} /> */}
  <Route exact path= "/homecare" element={<PortfolioHomecare/>}
  />
      <Route exact path="/blog" element={<ResearchandDev/>} />

        <Route exact path="/career" element={<Career />} />
        <Route exact path="/privacypolicy" element={<Privacypolicy />} />
        <Route exact path="/termscondition" element={<Termscondition />} />

      
      </Routes>
      <ScrollToTop />
      <ToastContainer />
      <Footer />
    </Router>

  );
}

export default App;
