import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import mentestimonial from '../Images/Home/men testimonial.jpg';
import '../Css/ProductTestimonial.css'; 

const ProductTestimonal = () => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(1);

    const goToPreviousSlide = () => {
        if (sliderRef.current) {
            const previousSlide = (currentSlide - 1 + testimonialData.length) % testimonialData.length;
            setCurrentSlide(previousSlide);
            sliderRef.current.slickGoTo(previousSlide);
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            const nextSlide = (currentSlide + 1) % testimonialData.length;
            setCurrentSlide(nextSlide);
            sliderRef.current.slickGoTo(nextSlide);
        }
    };

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        afterChange: (current) => setCurrentSlide(current),
    };

    const testimonialData = [
        {
            image: mentestimonial,
            text: "Rashail infotech has been an absolute lifesaver for my business. Their team of experts provided top-notch IT support and tailored solutions that have significantly improved our productivity",
        },
        {
            image: mentestimonial,
            text: "Rashail infotech has been an absolute lifesaver for my business. Their team of experts provided top-notch IT support and tailored solutions that have significantly improved our productivity",
        },
        {
            image: mentestimonial,
            text: "Rashail infotech has been an absolute lifesaver for my business. Their team of experts provided top-notch IT support and tailored solutions that have significantly improved our productivity",
        },
        {
            image: mentestimonial,
            text: "Rashail infotech has been an absolute lifesaver for my business. Their team of experts provided top-notch IT support and tailored solutions that have significantly improved our productivity",
        },
    ];

    return (
        <div className='d-none d-md-block'>
            <div className="row mt-5 mb-5 client-feedback-mobile justify-content-center">
                <h2 className="mainheading-color text-center mb-5">Testimonial</h2>
               
                <div className="col-md-6 text-center">
                <div>
    <Slider ref={sliderRef} {...sliderSettings}>
        {testimonialData.slice(0, 3).map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
                <div className="d-flex justify-content-between">
                    {testimonialData.slice(0, 3).map((t, i) => (
                        <div key={i} className={`testimonial-image-container ${i === 1 ? 'middle' : 'blurry'}`}>
                            <img src={t.image} alt={`Testimonial ${i + 1}`} className={`img-fluid rounded-circle ${i === 1 ? 'middle' : 'blurry'}`} />
                        </div>
                    ))}
                </div>
                <div className="mx-5" style={{ backgroundColor: 'white', width: "600px" }}>
    <div className="card-body mx-5 ">
        <div className="testimonial-content mt-5" style={{ textAlign: 'center' }}>
            <p className='slidercard '>{testimonial.text}</p>
        </div>
    </div>
</div>

            </div>
        ))}
    </Slider>
</div>


</div>


<div className="col-md-4 text-end center-vertically  " style={{position:"absolute", marginTop:"200px", marginLeft:"600px"}}>
                    <div className='d-flex'>
                        <button className="circular-button mx-2" onClick={goToPreviousSlide}>
                            <i className="fa fa-chevron-left" />
                        </button>
                        <button className="circular-button  mx-2" onClick={goToNextSlide}>
                            <i className="fa fa-chevron-right" />
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default ProductTestimonal;
