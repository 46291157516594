import React from "react";
import Data from "../Constant/CaseStudyData";
import blogImg from '../Images/Home/Rectangle 16.png'
import { Link } from "react-router-dom";
import moment from "moment";
import bgImg from '../Images/Home/Rectangle 172.png'

const CaseStudies = () => {
  const url = new URL(window.location.href);
  const path = url.pathname.split("/").pop();



  const blogData = Data.find((blog) => blog.link === path);

  const date = new moment();

  if (!blogData) {
    return (
      <div className="flex items-center justify-center m-32 mt-10">
        <div className="d-flex flex-wrap items-center justify-center gap-10 overflow-x-auto mt-10">
          {Data.map((blog, index) => (
            <Link
              key={index}
              to={`/case-studies/${blog.link}`}
              className="mb-2 text-blue-600"
            >
              <div className="w-[330px] border-2 p-4 rounded-lg flex flex-col items-center justify-center">
                <img src={blogImg} alt={blog.title} className="w-[300px]" />
                <div className="mt-4">
                  <h3 className="text-base">{blog.title}</h3>
                </div>
                <div className="rounded-2xl bg-blue-500 p-2 px-4 w-fit mt-4">
                  <button className="text-white font-semibold">Read More</button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }

  const { title, sections } = blogData;

  // Filter out the current blog from the Data array
  const otherBlogs = Data.filter((blog) => blog.link !== path);

  return (
    <div className=" m-10 md:m-32 mt-10">
      <div className="case-study  flex flex-col">
        <h1 className="text-green-600 ">{title}</h1>

<div className=" flex flex-row items-start mt-2">
        <h2 className=" text-sm md:text-base mr-3">Date Published : {date.format('MM/DD/YYYY')}</h2>
        |
        <span className=" text-sm md:text-base mx-3">Author : {blogData.author}</span>
        </div>

        <hr/>

<div className=" w-full">
<div className="  ">
         <div className=" flex items-center justify-center mt-10">
          <img src={bgImg} alt="" className="md:w-8/12"/>
        </div>

        <div className=" md:w-6/12 md:mx-auto mt-10">
        {sections.map((section, secIndex) => (
          <div key={secIndex} className="section mb-4">
            <h2 className="text-blue-600 text-2xl">{section.heading}</h2>
            <p className=" text-lg">{section.content}</p>
            {section.subsections &&
              section.subsections.map((subsection, subIndex) => (
                <div key={subIndex} className="subsection ml-4">

                  <ul className=" list-disc">
                    <li>
                  <h3 className="text-black text-xl">{subsection.subheading}</h3>
                  <p>{subsection.content}</p>
                  </li>
                  </ul>
                </div>
              ))}
          </div>
        ))}
        </div>
      </div>
      </div>
      </div>

      <div className="mt-10">
        <h2 className="text-blue-800 text-center mb-4">
          Blogs that might interest you
        </h2>
        <div className="flex flex-row items-center justify-center gap-10 overflow-x-auto mt-10">
          {otherBlogs.map((blog, index) => (
            <Link
              key={index}
              to={`/case-studies/${blog.link}`}
              className="mb-2 text-blue-600"
            >
              <div className=" w-[330px] border-2 p-4  rounded-lg  flex flex-col items-center justify-center ">
                <img src={blogImg} alt="" className=" w-[300px]" />
                <div className=" mt-4">
                  <h3 className="text-base ">{blog.title}</h3></div>

                  <div className=" rounded-2xl bg-blue-500 p-2 px-4 w-fit mt-4  ">
                    <button className=" text-white font-semibold">Read More</button>
                  </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
