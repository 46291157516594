import React, { useState } from "react";
import { Helmet } from "react-helmet";
import '../Css/Home.css';
import axios from "axios";

const Contactus = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    newsletter: false,
    attachment: null,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.post('https://preprod.dashboard.rashailagro.in/rashailenquiry/send-email/', {
        message: `You have received a new enquiry from ${formData.firstName} ${formData.lastName},\n Email - ${formData.email}\n Mob No.- ${formData.phone}\n Message: \n ${formData.message} `,
        server: 'rashail-tech-labs',
        subject: 'New Enquiry From Rashail Tech Labs website',
      }, {
        headers: {
          Authorization: "Token " + "0ea888c04c1c996b41f4084cf25090b4a603d098",
          "Content-Type": "application/json",
        }
      });
      setSuccess('Form submitted successfully');
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      setError('Error submitting form. Please try again later.');
      console.error('Error submitting form:', error);
    }
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      attachment: e.target.files[0],
    }));
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: '#1388CD' }}>
      <Helmet>
        <title>Contact Rashail Tech Labs - Get in Touch for Expert IoT Solutions & Consultancy</title>
        <meta name="description" content="Contact Rashail Tech Labs to discuss your IoT & Automation Solutions, explore our consultancy services, or inquire about partnership opportunities. Our team is ready to provide expert guidance and solutions tailored to your business goals." />
      </Helmet>
      <div className="row contactustopbackground">
        <div className="col-md-11 col-sm-12 text-center mt-5 mx-auto">
          <h1 className="serviceheading mt-5 ">
            <span className="serviceheading-color">Contact</span> Us
          </h1>
          <p className="serviceparaheading mt-4 mb-5">
            It's easy to connect with us. You can call us or write us a message below.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center pb-5">
        <div className="col-md-6 mt-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.861894568414!2d75.86341567476073!3d22.69618452843049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd3c0a8ef0fd%3A0x406b8e6797d06f57!2sRashail%20Tech%20Labs%20Private%20Limited!5e0!3m2!1sen!2sin!4v1714734532522!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="border-0"
          ></iframe>
        </div>
        <div className="col-md-4 text-end mt-5 py-5 mx-5">
          <div className="card contactus-card py-5 mt-5" id="contact-card">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row mt-2 mx-3">
                <div className="col-sm-6 mb-2">
                  <input
                    type="text"
                    className={`form-control form-c-us formplaceholder`}
                    placeholder="First Name"
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6 mb-2">
                  <input
                    type="text"
                    className={`form-control form-c-us formplaceholder`}
                    placeholder="Last Name"
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row mt-md-4 mx-3">
                <div className="col-sm-6 mb-2">
                  <input
                    type="email"
                    className={`form-control form-c-us formplaceholder`}
                    placeholder="Email"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6 mb-2">
                  <input
                    type="text"
                    className={`form-control form-c-us formplaceholder`}
                    placeholder="Phone"
                    required
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mt-md-4 mx-4">
                <textarea
                  type="text"
                  className={`form-control form-c-us formplaceholder`}
                  rows={6}
                  name="message"
                  placeholder="Write a message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <div className="row custom-control custom-checkbox text-start mt-3 mb-3">
                <div className="col-sm-6 mb-2">
                  <input
                    className="form-control choose-file-c form-control-sm"
                    id="formFileLg"
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              {success && (
                <div className="alert alert-success text-center" role="alert">
                  {success}
                
                </div>
              )}
              {error && (
                <div className="alert alert-danger text-center" role="alert">
                  {error}
                </div>
              )}
              <div className="text-center text-md-end mx-3 mt-2 mb-2">
                <button type="submit" className="btn buttonfunctionaity">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
