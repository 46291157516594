import React from 'react';
import Slider from 'react-slick';
import sliderlogo1 from '../Images/Sliderlogo/Eicher-logo-1920x1080.png';
import sliderlogo5 from '../Images/Sliderlogo/Maharshi-Vitiligo-Centres-Logo 2.png';
import sliderlogo6 from '../Images/Sliderlogo/RSFP-logo.png';
import sliderlogo7 from '../Images/Sliderlogo/Sun-Pharma.png';
import sliderlogo8 from '../Images/Sliderlogo/cropped-cropped-cbn-india-logo.png';
import sliderlogo9 from '../Images/Sliderlogo/logonavbar_yolo 2.png';

const OurCollaborativeVentures = () => {
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500, 
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, 
        cssEase: 'linear',
        pauseOnHover: true, 
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="row justify-content-center mb-5 mt-10">
           
            <div className="col-md-10 text-center">
                <Slider className="custom-slider mb-5" {...settings1}>
                    <div>
                        <img src={sliderlogo1} alt="Logo 1" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                    <div>
                        <img src={sliderlogo5} alt="Logo 5" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                    <div>
                        <img src={sliderlogo6} alt="Logo 6" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                    <div>
                        <img src={sliderlogo7} alt="Logo 7" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                    <div>
                        <img src={sliderlogo8} alt="Logo 8" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                    <div>
                        <img src={sliderlogo9} alt="Logo 9" className="img-fluid" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default OurCollaborativeVentures;
