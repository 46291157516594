import React, { useState } from 'react'
import workspace from '../Images/Career/Pexels Photo by Edmond Dantès.png'
import careermiddle from '../Images/Career/career page.png'
import { Link } from 'react-router-dom'
import call from '../Images/Career/5874327901556274003 2.png'
import message from '../Images/Career/Group.png'
import { Card, Modal, Button } from 'react-bootstrap';
import ApplicationFormModal from '../Components/Applicationformmodel'



const Career = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    // const handleModalClose = () => {
    //     setShowModal(false);
    // };

    // const [showModal, setShowModal] = useState(false);
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
        setShowApplicationForm(false);
    };

    const handleApplyNowClick = () => {
        // Set both modals to open
        setShowModal(true);
        setShowApplicationForm(true);
    };

    return (
        <div className='container-fluid'>
            <div className="row careerustopbackground">
                <div className="col-md-11 col-sm-12 text-center mx-auto mt-2">
                    <h2 className='serviceheading mt-md-5'>Come on <span className='serviceheading-color'>board</span> with us
                    </h2>
                    <p className='serviceparaheading mt-md-5'>
                        Get on board for a <span className='roller-coaster'>ROLLER COASTER</span>  career path. If you think you are
                        capable of getting along with challenges, feel free to hop onboard.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10 col-sm-10 text-center mx-auto">
                    <h4 className='mainheading-color mt-5'>How Our Workspace different from Others?</h4>
                    <img src={workspace} alt="workspace Image" className="img-fluid" />
                </div>
            </div>
            <div className="row text-center">
                <div className="col-10 mx-auto">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header paraheading" id="flush-headingOne">
                                <button class="accordion-button collapsed paraheading" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <span className="checkbox"></span> Culture of Collaboration
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body paraheading text-start">Culture of Collaboration</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed paraheading" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span className="checkbox"></span> Employee-Centric
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body paraheading text-start">Employee-Centric</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed paraheading" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <span className="checkbox"></span>Professional Development
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body paraheading text-start">Professional Development</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed paraheading" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <span className="checkbox"></span>Learning Ecosystem
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body paraheading text-start">Learning Ecosystem</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFive">
                                <button class="accordion-button collapsed paraheading" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    <span className="checkbox"></span>Open Communication
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body paraheading text-start">Open Communication</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12 mb-5 mt-3'>
                <img src={careermiddle} alt="workspace Image" className="img-fluid" style={{ margin: "0", padding: "0", width: "100%" }}/>
            </div>
            <div className="row justify-content-center">
                <div className='col-md-10'>
                    <h5 className='mt-4 mb-4 text-center mx-auto mainheading-color'>View Open Roles</h5>
                    <p className=' text-center mx-auto paraheading'>
                        We promise you to keep learning and enhancing new skills with a wide variety
                        of projects in your specialized domain.
                    </p>
                </div>
            </div>
            <div>
                <div className="row justify-content-center align-items-center mt-5 mb-5">
                    <div className="col-md-3 mx-1 ">
                        <div className="card card-with-shadow-portfolio " >
                            <div className="card-body portfolio-fixed-card-body" style={{ overflow: "hidden", width: "100%", height: "340px" }}>
                                <h5 className="card-title mt-2 mb-2 portfoliocardheading carrer-card" >Website Developers</h5>

                                <p className="card-text portfoliocardparaheading carrer-card_h">Experience: Min 2 Years of relevant work experience</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Role: On-site/Fulltime</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Location: Indore, MP</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Skills: HTML, CSS, Javascript</p>
                                <p className='portfoliocardsubheading carrer-card_h '>Job description →</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-1 ">
                        <div className="card card-with-shadow-portfolio " onClick={handleModalOpen}>
                            <div className="card-body portfolio-fixed-card-body" style={{ overflow: "hidden", width: "100%", height: "340px",cursor:"pointer" }}>
                                <h5 className="card-title mt-2 mb-2 portfoliocardheading carrer-card" >Application Developers</h5>

                                <p className="card-text portfoliocardparaheading carrer-card_h">Experience: Min 2 Years of relevant work experience</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Role: On-site/Fulltime</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Location: Indore, MP</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Skills: Android, ios, Java, Python</p>
                                <p className='portfoliocardsubheading carrer-card_h'>Job description →</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-1 ">
                        <div className="card card-with-shadow-portfolio " >
                            <div className="card-body portfolio-fixed-card-body" style={{ overflow: "hidden", width: "100%", height: "340px" }}>
                                <h5 className="card-title mt-2 mb-2 portfoliocardheading carrer-card" >UI UX Designers</h5>

                                <p className="card-text portfoliocardparaheading carrer-card_h">Experience: Min 2 Years of relevant work experience</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Role: On-site/Fulltime</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Location: Indore, MP</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Skills: Figma, Adobe XD, Sketch</p>
                                <p className='portfoliocardsubheading carrer-card_h'>Job description →</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-1 mt-3">
                        <div className="card card-with-shadow-portfolio " >
                            <div className="card-body portfolio-fixed-card-body" style={{ overflow: "hidden", width: "100%", height: "340px" }}>
                                <h5 className="card-title mt-2 mb-2 portfoliocardheading carrer-card" >Content Writers</h5>

                                <p className="card-text portfoliocardparaheading carrer-card_h">Experience: Min 1 Year of relevant work experience</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Role: On-site/Fulltime</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Location: Indore, MP</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Skills: Research skills, Creativity</p>
                                <p className='portfoliocardsubheading carrer-card_h'>Job description →</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-1 mt-3">
                        <div className="card card-with-shadow-portfolio " >
                            <div className="card-body portfolio-fixed-card-body" style={{ overflow: "hidden", width: "100%", height: "340px" }}>
                                <h5 className="card-title mt-2 mb-2 portfoliocardheading carrer-card" >QA Engineers</h5>

                                <p className="card-text portfoliocardparaheading carrer-card_h">Experience: Min 1 Year of relevant work experience</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Role: On-site/Fulltime</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Location: Indore, MP</p>
                                <p className="card-text portfoliocardparaheading carrer-card_h">Skills: API & Performance Testing</p>
                                <p className='portfoliocardsubheading carrer-card_h'>Job description →</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className='col-md-10'>
                    <h5 className='mt-4 mb-4 text-center mx-auto mainheading-color'>How To Apply?</h5>
                    <section className='career-backround-apply'>
                        <span>
                            <p className='paraheading-career-apply text-center '>Full Time Profile</p>
                            <p className=' text-center mx-auto paraheading'>
                                Want to switch you career in a different path.
                                Share your resume if you are skilled with any tech we deal in.
                                Our Talent Acquisition team will connect.
                            </p>
                        </span>
                        <span>
                            <p className='paraheading-career-apply text-center '>Team/Collaborators</p>
                            <p className=' text-center mx-auto paraheading'>
                                If you are a startup/small
                                business company and want to collaborate with us for a project,
                                feel free to send us a proposal with requirements. Our Technical
                                Team will connect.
                            </p>
                        </span>
                        <span>
                            <p className='paraheading-career-apply text-center '>Interns</p>
                            <p className=' text-center mx-auto paraheading'>
                                If you are a college student and want to enhance your
                                skills, look at the interns portal for proper dates to apply. We
                                might have something for you as well.
                            </p>
                        </span>
                    </section>


                </div>
            </div>
            <div>
                <div className="row justify-content-center align-items-center pb-5 mt-5 mb-5">
                    <div className="col-md-3 ">
                        <div className="card card-with-shadow-portfolio card-career" >
                            <div className="card-body portfolio-fixed-card-body d-flex " style={{ overflow: "hidden", width: "100%", height: "100%" }}>
                                <div >
                                    <img src={message} alt="workspace Image" className="img-fluid mt-3" />
                                </div>
                                <div className='mx-2'>
                                    <p className="card-text careercardparaheading">Share Your Updated CV</p>
                                    <p className='careercardsubheading  '>hr@rashailinfotech.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 ">
                        <div className="card card-with-shadow-portfolio card-career" >
                            <div className="card-body portfolio-fixed-card-body d-flex" style={{ overflow: "hidden", width: "100%", height: "100%" }}>
                                <div >
                                    <img src={call} alt="workspace Image" className="img-fluid" />
                                </div>
                                <div className='mx-2'>
                                    <p class="card-text careercardparaheading">Call Us</p>
                                    <p className='careercardsubheading '>+91 8602793619</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title className='mainheadingcareer'>Application Developers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='mainheadingcareer'>Job Description</h4>
                    <p>• A lot of passion for problem-solving programming & web development and setting up good practices.</p>
                    <p>• Should possess good communication capabilities.</p>
                    <p>• Should be a perfect team player with a go-getter attitude, result-driven, adaptable, inspirational, organized, and quality-focused.</p>
                    <p>• Understanding of best standards, guidelines, etc.</p>
                    <p>• Ability to handle complex problems and design to execute and deliver in a time-bound manner under constraints.</p>
                    <h4 className='mainheadingcareer'>Required Job Skills</h4>
                    <p>• Strong proficiency in React Js.</p>
                    <p>• 2+ years of professional experience working with React js.</p>
                    <p>• The candidate should be proficient in building applications in React.</p>
                    <p>• Knowledge of how to interact with RESTful APIs and formats (JSON, XML).</p>
                    <p>• Knowledge of state management frameworks like Redux or Flux.</p>
                    <p>• Proficiency in JavaScript module systems.</p>
                    <button className='buttonfunctionaity1' onClick={handleApplyNowClick}>
                        Apply Now
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* New modal for the application form */}
            <ApplicationFormModal show={showApplicationForm} handleClose={handleModalClose} />
        </div>
    )
}

export default Career
