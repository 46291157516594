import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/Navbar/footer-icon.png";
import "../Css/Home.css";
import { BsLinkedin } from "react-icons/bs";

const Footer = () => {
  const DivStyle = {
    backgroundColor: "#2F2F2F",
    // height: "382px"
  };
  return (
    <div className="container-fluid">
      <div className="row" style={DivStyle}>
        <div className="col-md-4 col-sm-4 px-5 py-2 order-md-1 order-2">
          <div className="card footer-card">
            <div className="card-body">
              <a className="navbar-brand mx-10" href="#">
                <img src={Logo} alt="Logo" className=" mx-3" />
              </a>
              <p className="footerhead pb-2 mx-4">
                Find The Best Consultants For Your Needs!
              </p>



              <div className="footeraddress mx-4">
    <a href="https://www.google.com/maps/place/Rashail+Tech+Labs+Private+Limited/@22.6962232,75.8658545,21z/data=!4m14!1m7!3m6!1s0x3962fd3c0a8ef0fd:0x406b8e6797d06f57!2sRashail+Tech+Labs+Private+Limited!8m2!3d22.6961796!4d75.8659906!16s%2Fg%2F11j9ccqn7n!3m5!1s0x3962fd3c0a8ef0fd:0x406b8e6797d06f57!8m2!3d22.6961796!4d75.8659906!16s%2Fg%2F11j9ccqn7n?entry=ttu" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <i className="fa fa-map-marker mx-1" aria-hidden="true"></i>
        S1, Malwa <br />
        &nbsp;&nbsp;&nbsp; Tower, Ashok Nagar, <br />
        &nbsp;&nbsp;&nbsp; Indore, Madhya Pradesh- <br />
        &nbsp;&nbsp;&nbsp; 452001 <br />
    </a>
</div>



              <div className="mt-3 footeraddress mx-4">
                <i
                  className="fa fa-phone"
                  aria-hidden="true"
                  style={{ color: "white", marginRight: "8px" }}
                ></i>
                <a
                  href="tel:+918602793619"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  +91 8602793619
                </a>
              </div>
              <div className="mt-3 footeraddress mx-4">
                <i
                  className="fa fa-envelope"
                  aria-hidden="true"
                  style={{ color: "white", marginRight: "8px" }}
                ></i>
                <a
                  href="mailto:rashailtech@gmail.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  rashailtech@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Second Column */}
        <div className="col-md-8 col-sm-8 order-md-2 order-3">
          <div className="row justify-content-center footer-column">
            {/* Pages Column */}
            <div className="col-md-3  col-sm-3 mb-4 px-4 col-xs-12">
              <p className="fs-16 mb-3 mt-4 letter-spacing-05 footerhead-n">
                Pages
              </p>
              <ul className="list-unstyled mb-0">
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/aboutus"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    About Us
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/services"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Services
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/portfolio"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Portfolio
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/contactus"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* Quick Links Column */}
            <div className="col-md-3 col-xs-12 col-sm-3 mb-4 px-4 footer-column">
              <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead-n">
                Quick Links
              </p>
              <ul className="list-unstyled mb-0 ">
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/portfolio"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Portfolio
                  </Link>
                </li>

                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/privacypolicy"
                    className="text-gray hover-white footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/termscondition"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Terms Of Use
                  </Link>
                </li>
              </ul>
            </div>

            {/* Newsletter Column */}
            <div className="col-md-5 col-sm-3  col-xs-12 px-4 footer-column">
              <p className="fs-16 mb-3 letter-spacing-05 mt-3 footerhead-n">
                Division
              </p>

              <Link
                to="https://rashailautomation.com/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="footerhead py-1">Rashail Automation</div>
              </Link>

              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="footerhead py-1">Rashail Tech Labs</div>
              </Link>

              <Link
                to="https://rashailinfotech.co.in/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="footerhead py-1">Rashail Infotech</div>
              </Link>

              <Link
                to="https://rashailagro.in/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="footerhead py-1">Rashail Agro</div>
              </Link>

              <div className="mt-2 ">
                <p className="footerhead-n">Follow our social network</p>
                <div className="mt-2">
                  <Link
                    to="https://www.linkedin.com/company/rashail-tech-labs/mycompany/"
                    target="_blank"
                  >
                    <BsLinkedin size={30} color="white" />
                  </Link>
                </div>
              </div>
              <p
                className="text-start mt-4 d-md-none"
                style={{ color: "#FFFFFF", fontSize: "12px" }}
              >
                Copyright © 2024 Rashail Tech Labs, All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright Section */}
      <div className="row justify-content-center mt-2">
        <div className="col-md-10 col-sm-12 mt-sm-2 text-center copyright mb-2">
          Copyright © 2024 Rashail Tech Labs, All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
