import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderlogo1 from "../Images/Sliderlogo/Eicher-logo-1920x1080.png";
import sliderlogo5 from "../Images/Sliderlogo/Maharshi-Vitiligo-Centres-Logo 2.png";
import sliderlogo6 from "../Images/Sliderlogo/RSFP-logo.png";
import sliderlogo7 from "../Images/Sliderlogo/Sun-Pharma.png";
import sliderlogo8 from "../Images/Sliderlogo/cropped-cropped-cbn-india-logo.png";
import sliderlogo9 from "../Images/Sliderlogo/logonavbar_yolo 2.png";
import Landingimg from "../Images/Home/home - Image.png";
import card1 from "../Images/Home/Group 1686553684.png";
import card2 from "../Images/Home/Group 1686553685.png";
import card3 from "../Images/Home/Group 1686553686.png";
import Group from "../Images/Home/Group 1686553687.png";
import target from "../Images/Home/target-15.png";
import faq from "../Images/Home/faq.png";
import rectangle1 from "../Images/Home/Rectangle 171.png";
import rectangle2 from "../Images/Home/Rectangle 172.png";
import rectangle3 from "../Images/Home/Pexels Photo by Teona Swift.png";
import rectangle4 from "../Images/Home/Rectangle 16.png";
import rectangle5 from "../Images/Home/Rectangle 17.png";
import rectangle6 from "../Images/Home/Rectangle 173.png";
import Testimonials from "../Components/Testimonials";

import frame1 from "../Images/Home/Frame.png";
import frame2 from "../Images/Home/Frame (1).png";
import frame3 from "../Images/Home/Frame 11.png";
import frame4 from "../Images/Home/Frame10.png";
import frame5 from "../Images/Home/Frame (2).png";
import frame6 from "../Images/Home/Frame (3).png";

import frame7 from "../Images/Home/Frame (4).png";
import frame8 from "../Images/Home/Frame (5).png";

import frame9 from "../Images/Home/Frame (7).png";

import frame10 from "../Images/Home/Frame (6).png";
import { Helmet } from "react-helmet";
import OurCollaborativeVentures from "../Components/OurCollaborativeVentures";

const Home = () => {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <div className="container-fluid ">
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Rashail Tech Labs",
            "url": "https://rashailtechlabs.co.in/",
            "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLjOEyhiiXJBq9y_TpPrufoMva_48V9P5dm55-mB5HHg&s",
            "sameAs": [
              "https://www.linkedin.com/company/rashail-tech-labs/mycompany/"
            ],
            "contactPoint": [
              {
                "@type": "ContactPoint",
                "telephone": "+918602793619",
                "contactType": "technical support",
                "email": "rashailtech@gmail.com",
                "areaServed": "IN",
                "availableLanguage": [
                  "en",
                  "hi"
                ]
              }
            ]
          }
          `}
        </script>

        <title>Rashail Tech Labs - Innovative IoT & Automation Solutions</title>

        <meta
          name="description"
          title=" Transforming Businesses with IoT & Automation Solutions - Rashail Tech Labs"
          content="Find out more about Rashail Tech Labs. We’ve got the technology and the expertise to fulfill all your IoT & Automation Solutions. We empower your business to flourish in the digital realm. "
        />

        <link rel="canonical" href="https://rashailtechlabs.co.in/" />
      </Helmet>

      <div className="row topbackground justify-content-center">
        <div className="col-md-6 text-center">
          <h1 className="mt-5 buildbusiness text-start">
            Managed{" "}
            <span className="buildbusiness-color">
              IoT & Automation Solutions for Businesses,
            </span>
            <br /> Everywhere
          </h1>
          <h2 className="mt-3 paraheading text-start">
            Transforming businesses with powerful IoT & Automation Solutions
            that fulfill the needs of today and unlock the potential of
            tomorrow.
          </h2>

          <div className="mt-4 d-flex mb-2 mb-md-0 ">
            <Link
              to="/contactus"
              style={{ color: "#7738F8", textDecoration: "none" }}
            >
              <button className="btn buttonfunctionaity btn-blocks">
                Get a Quote
              </button>
            </Link>
          </div>
        </div>
        <div className="col-md-4 text-end">
          <img
            src={Landingimg}
            alt="Man on computer"
            className="img-fluid mx-md-4"
          />
        </div>
      </div>


      <h4 className="mainheading text-center mb-5 mt-2">
                Our <span className="mainheading-color">Collaborative Ventures</span>
            </h4>
     <OurCollaborativeVentures/>

      <div className="row justify-content-center mt-5">
        <div className="col-md-10 text-center mx-auto">
          <h2 className="mainheading mb-5">
            Industries <span className="mainheading-color">We Transformed</span>
          </h2>

          <div className=" flex flex-col md:flex-row items-center justify-between mt-3 w-full gap-6">
            <div className="  ">
              <div
                className="card borderless-card industries-home-card "
                style={{ width: "25rem", position: "relative" }}
              >
                <img
                  src={card1}
                  className="card-img-top mt-4"
                  alt="Nurse with a stethoscope"
                  title="Nurse"
                />
                <h3 className="card-text-overlay1">Healthcare</h3>
                <div className="card-body">
                  <p
                    className="card-text-home -mt-2"
                    style={{ overflow: "hidden" }}
                  >
                    Engineered an IoT Enabled Phototherapy Booth for Vitiligo
                    Treatment, transforming people's lives. Collaborated with
                    the Government of India, to produce Health ATM Kiosks in
                    addition to a SaaS Platform to connect Doctors and Patients.
                  </p>

                  {/* <Link to= '/services'>

                    <button   className="btn buttonfunctionaity-card hover:cursor-pointer "
                    style={{ marginTop: "-4px" }}>
                    Read More
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className=" ">
              <div
                className="card borderless-card industries-home-card"
                style={{ width: "25rem" }}
              >
                <img
                  src={card2}
                  className="card-img-top"
                  alt="2 people farming"
                  title="Farming"
                />
                <h3 className="card-text-overlay1">AgriTech</h3>
                <div className="card-body">
                  <p
                    className="card-text-home"
                    style={{ height: "", overflow: "hidden" }}
                  >
                    Pioneered a Smart Farm Weather Station as well as a
                    Centralised Unit to monitor Micro-climatic Conditions of the
                    farm, and provide real-time analytical values to the farmers
                    for their crop and soil health.
                  </p>
                  {/* <a href="/services" className="btn buttonfunctionaity-card mt-3">
                    Read More
                  </a> */}
                </div>
              </div>
            </div>

            <div className=" ">
              <div
                className="card borderless-card industries-home-card"
                style={{ width: "25rem" }}
              >
                <img
                  src={card3}
                  className="card-img-top"
                  alt="IoT Professional"
                  title="Person"
                />
                <h3 className="card-text-overlay1  ">Commercial</h3>
                <div className="card-body">
                  <p className="card-text-home" style={{ overflow: "hidden" }}>
                    Fabricated and Installed Smart Lighting Solutions at IIT
                    Indore and SGSITS Indore with Remote operation and
                    IoT-enabled technology; in addition to photogenic sensors
                    for maximum efficiency.
                  </p>
                  {/* <a href="#" className="btn buttonfunctionaity-card mt-3 ">
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center whatweoffer mt-3">
        <div className="col-md-10 text-center">
          <h2 className="mainheading mt-5">
            Why{" "}
            <span className="mainheading-color">
              {" "}
              Choose Rashail Tech Labs?
            </span>
          </h2>
          <div className="row mt-md-3">
            <div className="col-md-6 ">
              <p className="paraheading text-start mt-md-5">
                Rashail Tech Labs has been working on multi-disciplinary
                projects since the very beginning of its formation. We manage
                ALL IT aspects, including hardware, firmware, user interface,
                and software development, utilizing our seasoned in-house team.{" "}
              </p>
              <h3 className="paraheading text-start flex items-center gap-1">
                <img
                  src={target}
                  className="img-fluid"
                  alt="Research & Development
                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                Research & Development{" "}
              </h3>
              <h3 className="paraheading text-start flex items-center gap-1">
                <img
                  src={target}
                  className="img-fluid"
                  alt="Leading Space In IT Market
                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                Leading Space In IT Market{" "}
              </h3>
              <h3 className="paraheading text-start flex items-center gap-1">
                <img
                  src={target}
                  className="img-fluid"
                  alt="Embedded Prototyping
                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                Embedded Prototyping{" "}
              </h3>
              <h3 className="paraheading text-start flex items-center gap-1">
                <img
                  src={target}
                  className="img-fluid"
                  alt="PCB Design & Production
                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                PCB Design & Production{" "}
              </h3>
              <h3 className="paraheading text-start flex items-center gap-1">
                <img
                  src={target}
                  className="img-fluid"
                  alt="Industrial Automation                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                Industrial Automation{" "}
              </h3>
              <h3 className="paraheading text-start flex items-center gap-1 ">
                <img
                  src={target}
                  className="img-fluid"
                  alt="Product Design & 3D Printing
                  "
                  style={{ width: "40px", height: "40px" }}
                />{" "}
                Product Design & 3D Printing{" "}
              </h3>
            </div>
            <div className="col-md-6 mb-4">
              <img
                src={Group}
                alt="graphic imag pertaining to IoT"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-10 text-center mx-auto ">
          <h2 className="mainheading mt-3">
            How we can{" "}
            <span className="mainheading-color">Transform your Business</span>
          </h2>
          <h3 className="paraheading mt-3">
            Experience innovation, efficiency, and excellence with Rashail Tech
            Labs – where your automation needs find a personalized and
            future-ready solution.
          </h3>

          <div class="container mt-5 mb-5">
            <div class="row">
              <div class="col-md-6 d-flex flex-column text-start justify-content-start align-items-stretch gap-5 p-3">
                <h2 class="text-6xl">
                  Next-generation <br />
                  digital services and consulting
                </h2>
                <p class="text-2xl">
                  We help you transform your business with advanced
                  technologies.
                </p>

                <div>
                  <Link to="/contactus">
                    <button class="btn btn-primary btn-lg">
                      Grow your business
                    </button>
                  </Link>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row row-cols-1 row-cols-md-2 g-4 text-start ">
                  <div class="col">
                    <div class="d-flex flex-row items-center justify-center">
                      <img src={frame1} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">
                        Electronics Circuit Design
                      </p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row  items-center justify-center">
                      <img src={frame3} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">Electronics Automation</p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex flex-row">
                      <img src={frame4} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">Hardware Integration</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row">
                      <img src={frame7} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">Engineering Services</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="flex flex-row items-center ">
                      <img src={frame6} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">Research and Development</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row items-center">
                      <img src={frame5} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">
                        Healthcare Device Automation
                      </p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row items-center">
                      <img src={frame8} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">AI & ML</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row">
                      <img src={frame9} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">Cloud Integration</p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row items-center">
                      <img src={frame10} alt="frame" class="m-1 w-8 h-8" />
                      <p class="mx-2 text-xl w-75">
                        IoT and Digital Engineering
                      </p>
                    </div>
                  </div>

                  <div class="col">
                    <div class="d-flex flex-row items-center">
                      <img src={frame2} alt="frame" class="m-1 w-8 h-8 pb-4" />
                      <p class="mx-2 text-xl w-75">
                        Product Prototyping & Development
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reviews */}
      <Testimonials />

      {/* Blogs and News */}
      <div className="row justify-content-center ">
        <div className="col-md-10 text-center">
          <h4 className="mainheading m-5">
            View Our <span className="mainheading-color">Blogs & News</span>{" "}
          </h4>
          <div className="row mt-3">
            <div className="col-md-4 col-6 col-sm-6 mb-3 mx-auto">
              <div
                className="card borderless-card home-card-blog"
                style={{ width: "18rem" }}
              >
                <img src={rectangle1} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="text-start blog-card-date">30-09-2023</p>
                  <p className="text-start blog-card-head">
                    In- Depth Industry & Ecosystem Analysis
                  </p>
                  <p className="text-start blog-card-head-m">
                    Driving excellence in your life by consulting with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 col-sm-6 mb-3">
              <div
                className="card borderless-card home-card-blog"
                style={{ width: "18rem" }}
              >
                <img src={rectangle2} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="text-start blog-card-date">30-09-2023</p>
                  <p className="text-start blog-card-head">
                    15 Best PHP Frameworks for 2023
                  </p>
                  <p className="text-start blog-card-head-m">
                    Driving excellence in your life by consulting with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 col-sm-6 mb-3">
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src={rectangle3}
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date-m">30-09-2023</p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle4} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle5} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle6} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row  justify-content-center ">
        <h3 className="mainheading text-center  mb-md-5">
          <span className="mainheading-color">F</span>requently{" "}
          <span className="mainheading-color">A</span>sked{" "}
          <span className="mainheading-color">Q</span>uestions
        </h3>
        <div
          className="col-md-5 col-sm-12 d-flex align-items-center"
          style={{ height: "600px" }}
        >
          <img
            src={faq}
            alt="Frequently Asked Questions"
            className="img-fluid question-mobile"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100%",
              maxHeight: "100%",
              marginBottom: "200px",
            }}
          />
        </div>
        <div className="col-md-5 col-sm-12 que-ans-mobile">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h4 class="accordion-header questionhome" id="flush-headingOne">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What services does your IoT company offer?
                </button>
              </h4>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  From conceptualization to implementation and ongoing support,
                  We offer end-to-end, complete service; You can rest assured
                  that your project needs will be met and exceeded with Rashail
                  Tech Labs.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h4 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Do you offer customized solutions for specific industries?
                </button>
              </h4>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  Yes, at Rashail Tech Labs, we take an all-inclusive approach
                  to understanding the intricacies of your business and how we,
                  from our industry experience; optimize your processes, address
                  your unique challenges, and deliver a custom-made digital
                  solution.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h4 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What industries have you served in the past?
                </button>
              </h4>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  Rashail Tech Labs has worked across Healthcare, the Food
                  Industry, Automobile Manufacturing, Industrial Automation, IoT
                  Applications, AgriTech, and a lot more. We excel at
                  collaborative partnerships, working closely with our clients
                  and jointly devising practical and effective solutions.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h4 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Do you offer training for staff on your IoT company?
                </button>
              </h4>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  Yes, we train and upskill staff on a regular basis and keep
                  them well-versed in the complexities of the software.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h4 class="accordion-header" id="flush-headingFive">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  How can I reach your support team in case of an emergency or
                  urgent issue?
                </button>
              </h4>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  It's easy to connect with us, at any point in time by calling
                  us at <a href="tel:+918602793619">+91 8602793619</a> ; or
                  getting in touch with your dedicated project manager, assigned
                  to you by Rashail Tech Labs.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h4 class="accordion-header" id="flush-headingSix">
                <button
                  class="accordion-button collapsed questionhome"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  What technology stack and tools do you typically use for
                  development?
                </button>
              </h4>
              <div
                id="flush-collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingSix"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body questionhome text-start">
                  At Rashail Tech Labs, we use present-day, contemporary
                  technologies to help us achieve client objectives and
                  ambitions. Here's a quick peek at our tech stacks and tools: -
                  Micro-controllers & Microprocessors: AVR, STM, PIC, ARM Cortex
                  - Wireless technology: LoRA, Zigbee, BLE, Bluetooth, WiFi, nRF
                  - All Analog & Digital Sensors - Cloud Tech: AWS, Custom
                  Servers - Protocols: HTTP, HTTPS, MQTT, CoAP - Computer Vision
                  & Data Analytics
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
