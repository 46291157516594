import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import mission from "../Images/aboutus/Rectangle 27.png";
import mentestimonial from "../Images/Home/men testimonial.jpg";
import rectangle1 from "../Images/Home/Rectangle 171.png";
import rectangle2 from "../Images/Home/Rectangle 172.png";
import rectangle3 from "../Images/Home/Pexels Photo by Teona Swift.png";
import rectangle4 from "../Images/Home/Rectangle 16.png";
import rectangle5 from "../Images/Home/Rectangle 17.png";
import rectangle6 from "../Images/Home/Rectangle 173.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OurCollaborativeVentures from "../Components/OurCollaborativeVentures";
import bg_url from '../Images/aboutus/bg_url.png'

const Aboutus = () => {




  return (
    <div className="container-fluid">
      <Helmet>
      <link rel="canonical" href="https://rashailtechlabs.co.in/aboutus" />


        <title>About Rashail Tech Labs - Empowering Businesses Through Technology</title>
        <meta name="description" title="Learn About Our Journey and Mission - Rashail Tech Labs
" content="Find out the story behind Rashail Tech Labs, and how our expert team is solving the problems through innovative IT solutions and consultancy, specifically designed for a business like yours." />
      </Helmet>

      <div className="row aboutustopbackground">
        <div className="col-md-11 col-sm-12 text-center mt-5 mx-auto">
          <h1 className="  serviceheading mt-5 ">
            <span className="serviceheading-color">About</span> Rashail Tech
            Labs{" "}
          </h1>
          <h2 className="serviceparaheading mt-4 mb-5">
          With a blend of expertise and ingenuity, we craft IoT & Automation Solutions that make the impossible possible.

          </h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <h2 className="mainheading mt-20">
            Bespoke  
            <span className="mainheading-color mx-2">
                 IoT & Automation Solutions that sweeten your top line
            </span>{" "}
          </h2>
          <h3 className="paraheading mt-10">
            At the heart of Rashail Tech Labs is a mission to empower businesses
            and boost their top line with the application of IoT and Automation
            technologies. <br /> Our commitment to innovation and supremacy has made us
            a force to be reckoned with; when it comes to technological
            solutions that seamlessly blend with your business operations.
          </h3>
        </div>
      </div>


      <h2 className="mainheading text-center mb-5 mt-20">
          Building
          <span className="mainheading-color">
            {" "}
            Greater Futures for our customers
          </span>
        </h2>
   <OurCollaborativeVentures/>
      <div className="row justify-content-center">
        <h2 className="mainheading text-center mb-5">
          Transforming{" "}
          <span className="mainheading-color">Business with technology.</span>
        </h2>
        <div class="col-md-5">
          <img src={mission} alt="Landingimg" className="img-fluid my-10 "  />
        </div>
        <div className="col-md-5">
          <h3 className="mt-5 paraheading  p-about-mobile">
          Our commitment to innovation and supremacy has made us a force to be reckoned with; when it comes to IoT & Automation Solutions that seamlessly integrate with your business operations.

          </h3>
        </div>
      </div>
      <div className="row justify-content-center  " style={{ marginTop: '20px' }}>
        <div className="col-md-10 journey-of-excellenc">
          <div className="col-md-10 journey-of-excellence-d-1">
            <h2 className="mainheading text-start">
              Our
              <span className="mainheading-color" style={{ marginLeft: '10px' }}>
                Journey of Excellence
              </span>
            </h2>


            <p className="paraheading text-start mb-3 my-8">


              Our journey began in July 2019, when we initially focused on offering consultation services to a diverse group of companies and startups. Focusing primarily on the Automobile, Agriculture, and Healthcare industries, we quickly found our calling.
         


            </p>


          </div>


          <Link to='/contactus'>
            <button className="buttonfunctionaity mt-4" >Get a Quote</button>
          </Link>
 
        </div>
      </div>


      <div className =" my-14 relative mx-32 hidden md:block">
    

    <div>
      <img src={bg_url} alt="" className=" absolut top-0 pt-14 "/>
    </div>
  
          <div className=" absolute -right-24 -top-9 ">
            <h3>Mass Manufacturing </h3>
            <p>Assembly and Fabrication of <br/>electronics,
           smart devices</p>
          </div>

          {/* <div className=" absolute top-44  right-96">
            <h3>COVID-19 Pandemic </h3>
            <p>Manufacturing high-tech UV <br /> chambers and sanitizers for the
              <br /> common good.</p>
          </div>

          <div className=" absolute top-80 left-52">
            <h3>Startup India Initiative, <br/>2020</h3>
            <p>Our new tech division- Smart Homes <br />and Automation</p>
          </div>

          <div className=" absolute
           bottom-20">
            <h3> Founded in 2019</h3>
            <p>Rashail Tech Labs' foundation as a premier <br />IT Consultancy firm.</p>
          </div> */}

          
 </div>


      <div className="row justify-content-center check-our-talent mt-2 mb-5">
        <div className="col-md-5 mt-5 ">
          <h4 className="mainheading text-start ">
            Our <span className="mainheading-color ">  Executive Leadership</span>
          </h4>
          <h3 className=" paraheading">
            Meet the leadership and talents propelling Rashail Tech Labs' forward.

          </h3>
        </div>
        <div class="col-md-5 mt-5">
          <div class="d-flex justify-content-between">
            <div
              class="card mx-2 checkout-aboutus"
              style={{ width: "12rem", borderRadius: "15px" }}
            >
              <img
                src={mentestimonial}
                alt="Landingimg"
                className="img-fluid"
                style={{ borderRadius: "15px 15px 15px 15px" }}
              />
              <div class="card-body">
                <p class="card-title checkout-aboutus-name">Saral Jain</p>
                <p class="card-text about-card-subheading">CEO & Co-founder</p>
              </div>
            </div>
            <div
              class="card mx-2 checkout-aboutus"
              style={{ width: "12rem", borderRadius: "15px" }}
            >
              <img
                src={mentestimonial}
                alt="Landingimg"
                className="img-fluid"
                style={{ borderRadius: "15px 15px 15px 15px" }}
              />
              <div class="card-body">
                <p class="card-title checkout-aboutus-name">Chetna Patidar</p>
                <p class="card-text about-card-subheading">Human Resource</p>
              </div>
            </div>
            <div
              class="card mx-2 checkout-aboutus"
              style={{ width: "12rem", borderRadius: "15px" }}
            >
              <img
                src={mentestimonial}
                alt="Landingimg"
                className="img-fluid"
                style={{ borderRadius: "15px 15px 15px 15px" }}
              />
              <div class="card-body">
                <p class="card-title checkout-aboutus-name">Amin</p>
                <p class="card-text about-card-subheading">
                  Front-end Developer
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-center mt-5 mb-5">
          <div className="bottom-image our-talent-aboutus">
            <h3 className="aboutus-talent-heading py-4">
              Join Our Team Of Excellence
            </h3>
            <h4 className="serviceparaheading mx-5">
            Join Rashail Tech Labs' team of genius IoT professionals, and contribute to the cyber success of exceptional firms.


            </h4>

            <Link to='/career'>
              <button className="buttonfunctionaity-about mt-2 mb-3" style={{ borderRadius: "15px" }}>
                View Job Openings
              </button>

            </Link>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-md-10 text-center">
          <h4 className="mainheading">
            View Our <span className="mainheading-color">Blogs & News</span>
          </h4>
          <div className="row mt-5">
            <div className="col-md-4 col-6 col-sm-6 mb-3 mx-auto">
              <div
                className="card borderless-card aboutus-card-blog"
                style={{ width: "18rem" }}
              >
                <img src={rectangle1} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="text-start blog-card-date">30-09-2023</p>
                  <p className="text-start blog-card-head">
                    In- Depth Industry & Ecosystem Analysis
                  </p>
                  <p className="text-start blog-card-head-m">
                    Driving excellence in your life by consulting with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 col-sm-6 mb-3 mx-auto">
              <div
                className="card borderless-card aboutus-card-blog"
                style={{ width: "18rem" }}
              >
                <img src={rectangle2} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="text-start blog-card-date">30-09-2023</p>
                  <p className="text-start blog-card-head">
                    15 Best PHP Frameworks for 2023
                  </p>
                  <p className="text-start blog-card-head-m">
                    Driving excellence in your life by consulting with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle3} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body ">
                      <p className="text-start blog-card-date-m">30-09-2023</p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle4} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle5} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card mb-3 borderless-card card-home-blog-h"
                style={{ maxWidth: "540px", borderBottom: "1px solid #3D474E" }}
              >
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={rectangle6} className="card-img-top" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p className="text-start blog-card-date blog-card-date-m">
                        30-09-2023
                      </p>
                      <p className="text-start blog-card-head-m">
                        15 Best PHP Frameworks for 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 className="mainheading text-center mb-5 mt-2">
          View Our <span className="mainheading-color">Collaborative Ventures</span>{" "}
        </h4>

  <OurCollaborativeVentures/>
    </div>
  );
};

export default Aboutus;
