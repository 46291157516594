import React from "react";
import "../Css/UVPhoto.css";
import hardwareImg from "../Images/UVPhotos/7573077111594941672 1.png";
import softwareImg from "../Images/UVPhotos/14687028791674364816 1.png";
import Blublogo from "../Images/UVPhotos/68620945516345278634481 1.png";
import footerImg from "../Images/UVPhotos/FooterImg.png";
import { Profoliodata } from "../Constant/data";
import ResearchandDev from "../Components/ResearchandDev";
import { Helmet } from "react-helmet";


const UVPHOTOtherapy = () => {





  const url = new URL(window.location.href);
  const path = url.pathname.split('/').pop();

  const projectData = Profoliodata.find(project => project.link === path);



  const {
    title,
    subheading,
    sticker,
    cards_section,
    project_challenges,
    hardware,
    software,
    contribution,
    background,
    projectimage,
    Helmettitle,
    metaTitle,
    metaDescription,
    tech
  } = projectData;

 

  if (!Profoliodata) {
    return null;
  }



  
  return (
    <div class="padding-container">

     
<Helmet>
     

        <title>{Helmettitle}</title>

        <meta
          name="description"
          title={metaTitle}
          content={metaDescription}
        />
      </Helmet>


      <div class="relative-container">
        <img src={background} alt="BG" class="bg-image" />

        <div class="absolute-container  items-center justify-between px-20">
          <div class=" flex flex-col items-start justify-between w-600px">
            <h1 className=" text-white text-7xl  "
    
            >
              {title}
            </h1>
            <p className=" text-xl mt-10 text-white w-[400px]"
            
            >
              {subheading}
            </p>
          </div>

          <div class=" flex flex-col ">
           
            <img src={sticker} alt="icon" class=" mt-28 iconLogo " width={150} height={150} className="rounded-xl"
            />
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row justify-content-center">
          {cards_section.map((card, index) => (
            <div
              key={index}
              class="col-lg-3 col-md-3 col-sm-3 mb-4"
            >
              <div
                class="border p-4 rounded d-flex flex-column align-items-center justify-content-center"
                style={{
                  width: "100%",
                  height: "320px",
                }}
              >
                <div
                  className="bg-rounded d-flex align-items-center justify-content-center mb-3"
                  style={{
                    backgroundColor: card.styleColor,
                    width: "80px",
                    height: "80px",
                    borderRadius: '50%',
                  }}
                >
                  <img src={card.icon} alt="icon" className="img-fluid" style={{
                    backgroundColor: card.color,
                    padding: 8,
                    borderRadius: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }} />
                </div>

                <h3 class="fs-2 fw-bold py-3">{card.title}</h3>
                <p class="text-center">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div class="px-20  flex flex-column flex-md-row md:mx-40 justify-between mt-10">
        <div class="mt-10">
          <h2 class="text-blue-500 font-semibold text-4xl " style={{ paddingLeft: '20px' }}>
            Project Challenges
          </h2>
          <p class="md-w-600px mt-12 text-xl" style={{ paddingRight: '50px', paddingLeft: "10px" }}>{project_challenges}</p>
        </div>
        <img
          src={projectimage}
          alt={projectimage}
          class=""
          style={{
            height: "300px",
            width: "400px",
          }}
        />
      </div>



     <ResearchandDev techdata = {tech}/>

      {hardware && software && (
        <div className="flex flex-column flex-md-row gap-2 gap-md-5 justify-content-between  mt-5 px-20 w-full" >
          
          <div className=" boxwidth mt-3 shadow border p-4 p-md-5 position-relative text-xl rounded"
          >
            <img src={hardwareImg} alt="" className="position-absolute end-0 top-0" />
            <h3 className="text-primary font-weight-bold text-4xl my-4">Hardware</h3>
            <ul className="ps-4 " style={{listStyle: 'disc'}}>
              {hardware.points.split("\n").map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
            <div className="d-flex align-items-center">
              <img src={Blublogo} alt="logo" className="w-4 h-4 me-2" />
              <h3 className="text-primary font-weight-bold text-3xl my-4">Technologies Used</h3>
            </div>
            <p>{hardware.technologies_used}</p>
          </div>

          <div className=" boxwidth mt-3 shadow border p-4 p-md-5 position-relative text-xl rounded"
          >
            <img src={softwareImg} alt="" className="position-absolute end-0 top-0" />
            <h3 className="text-primary font-weight-bold text-4xl my-4">Software</h3>
            <ul className="ps-4"style={{listStyle: 'disc'}}>
              {software.points.split("\n").map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
            <div className="d-flex align-items-center">
              <img src={Blublogo} alt="logo" className="w-4 h-4 me-2" />
              <h3 className="text-primary font-weight-bold text-3xl my-4">Technologies Used</h3>
            </div>
            <p>{software.technologies_used}</p>
          </div>
        </div>
      )}


      <div class="  px-20 text-center  " style={{ marginTop: "100px" }}>
        <div class="d-flex align-items-center justify-content-center gap-4">
          <hr
            class="gradient-hr"
            style={{
              background: "linear-gradient(to right, #FCFEFF, #313fd7 80%)",
              width: "350px",
              height: "6px",
            }}
          />

          <h2 class="font-weight-bold text-4xl text-primary">
            Rashail’s Contribution
          </h2>
          <hr
            class="gradient-hr"
            style={{
              background: "linear-gradient(to left, #FCFEFF, #313fd5 80%)",
              width: "350px",
              height: "6px",
            }}
          />
        </div>

        <p class="text-start text-xl mx-3 mx-md-5 mt-4">{contribution}</p>
      </div>

      <img
        src={footerImg}
        alt="FooterImg"
        class="w-100 opacity-80  py-5"
        style={{ marginTop: "150px", marginBottom: "100px" }}
      />
    </div>
  );
};

export default UVPHOTOtherapy;
