import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Define a new component for the application form
const ApplicationFormModal = ({ show, handleClose }) => {
    // Add your form logic and JSX here

    return (
        <Modal show={show} onHide={handleClose}>
            {/* Add your form content here */}
            <Modal.Header closeButton>
                <Modal.Title>Application Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Add your form fields here */}
                {/* For example: */}
                <div className="card contactus-card my-3" id="contact-card">
                    <form>
                        <div className="row mt-2">
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className={`form-control formplaceholder`}
                                    placeholder="First Name"
                                    required=""
                                    name="name"
                                />
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="email"
                                    className={`form-control formplaceholder `}
                                    placeholder="Last Name"
                                    required=""
                                    name="email"
                                />
                            </div>
                        </div>
                        <div className="row mt-4 ">
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className={`form-control formplaceholder `}
                                    placeholder="Email"
                                    required=""
                                    name="mobileno"
                                />
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className={`form-control formplaceholder `}
                                    placeholder="Phone"
                                    required=""
                                    name="subject"
                                />
                            </div>
                        </div>
                        <div className="form-group mt-4 ">
                            <textarea
                                type="text"
                                className={`form-control formplaceholder`}
                                rows={6}
                                name="comment"
                                placeholder='Write a message'
                            />
                        </div>

                        <div className="row custom-control custom-checkbox text-start mt-3 mb-3">
                            <div className='col-sm-6'>
                                <input
                                    class="form-control form-control-sm"
                                    id="formFileLg"
                                    type="file" />
                            </div>
                            <div className='col-sm-6 text-end'>
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                />
                                <label
                                    className="custom-control-label fs-15 mx-1 formplaceholder"
                                    htmlFor="customCheck1">
                                    Send a Newsletter
                                </label>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn buttonfunctionaity col-md-10 ">
                            Submit
                        </button>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {/* Add a button to submit the form if needed */}
                {/* <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ApplicationFormModal;
