import React from "react";
import backgorundImg from "../Images/Homeccare_page/BgImg.png";
import ProductTestimonal from "../Components/ProductTestimonal";
import ProductFeatureCardGroup from "../Components/ProductFeatureCardGroup";
import OurCollaborativeVentures from "../Components/OurCollaborativeVentures";
import mobile1 from  '../Images/Homeccare_page/Img1.png';
import mobile2 from '../Images/Homeccare_page/Img2.png';
import mobile3 from '../Images/Homeccare_page/Img3.png';
import mobile4 from '../Images/Homeccare_page/Img4.png';

const PortfolioHomecare = () => {
  return (
    <div className="container-fluid px-">
      <div className="row justify-content-center align-items-center pt-5" style={{ backgroundImage: `url(${backgorundImg})`, backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="col-md-6 text-center">
          <p className="portfoliodetailswebsite text-start mx-5">
            <span className="dashportfoliodetails mx-2" style={{ width: "77px" }}></span>
            Mobile Application
          </p>
          <h4 className="serviceheading text-start ">
            Where Comfort Meets <br/><span className="serviceheading-color">Innovation! is "Homecore”</span>
          </h4>
        </div>
        <div className="col-md-4">
          <img
            src={mobile1}
            alt="Allmeds"
            className="img-fluid allmade-portfolio"
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <h2 className="mainheading-color mt-5">
            <span className=" text-black">
              “We are in the business of  
            </span>
            <br/>crafting Smart Home!"
          </h2>
          <p className="paraheading mt-4">
            At HomeCore, our mission is to revolutionize the way you experience and interact with your home. and more convenient through intuitive automation. We're here to protect what matters most to you.
          </p>
          <ProductFeatureCardGroup />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 text-center  px-4">
          <h4 className="mainheading-color text-md-end">Components</h4>
          <p className="paraheading text-start mt-3">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              User Registration & Authentication: 
            </span>
            User account creation and login functionality to personalize the user experience and ensure security.
          </p>
          <p className="paraheading text-start mt-5 ">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Dashboard: 
            </span>
            A centralized interface displaying an overview of all connected devices and their current status. Customizable widgets or cards for quick access and control of devices.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Device Management: 
            </span>
            Device discovery and pairing mechanisms to add new smart devices to the app. Device categorization and organization for easy navigation.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <img src={mobile2} alt="modile" className="img-fluid h-100 mt-3" />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-4 text-center">
          <img
            src={mobile3}
            alt="Allmeds "
            className="img-fluid mt-5"
          />
        </div>
        <div className="col-md-6 text-center mt-5 px-4">
          <h4 className="mainheading-color text-md-start">Attributes</h4>
          <p className="paraheading text-start mt-3">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Partnerships and Affiliates:
            </span>
            Information about partnerships with hotels, airlines, and tour operators. Affiliate marketing links and programs.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Accessibility Features:
            </span>
            Accessibility options for users with disabilities, such as screen readers and keyboard navigation.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Event Calendars:
            </span>
            Event calendars displaying local festivals, concerts, and cultural events. Users can add events to their itineraries.
          </p>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-md-6 text-center px-4">
          <h4 className="mainheading-color text-md-end">Perks</h4>
          <p className="paraheading text-start mt-3">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">Convenience: </span>
            The ability to automate daily routines, such as adjusting lighting, thermostats, and blinds according to preset schedules.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Cost-effective: 
            </span>
            Real-time energy monitoring and optimization of energy-consuming devices, helping users reduce their utility bills.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2"> Security:</span>
            Enhanced home security with features like remote door lock control, motion sensor alerts, and video surveillance.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <img src={mobile4} alt="Allmeds" className="img-fluid mt-4" />
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-md-10 text-center px-4">
          <h4 className="mainheading-color text-center">Advancements</h4>
          <p className="paraheading text-start mt-3">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Voice-Control: 
            </span>
            Integration with voice assistants like Amazon Alexa or Google Assistant for hands-free control of devices. Convenient voice commands for tasks like adjusting lighting, setting the thermostat, or playing music.
          </p>
          <p className="paraheading text-start mt-5 ">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Integration with Other Services: 
            </span>
            Integration with other services like weather forecasts, news updates, and traffic information. Centralized access to various aspects of daily life through a single app.
          </p>
          <p className="paraheading text-start mt-5">
            <span className="arrow-wrapper">&#10004;</span>
            <span className="portfolio-d-front_text mx-2">
              Enhanced data privacy:
            </span>
            Our software ensures the security and privacy of Users data, maintaining confidentiality and complying with data protection regulations.
          </p>
        </div>
      </div>

      {/* Product Testimonials Component  */}
      <ProductTestimonal />

      <div className="row teamfoliobackground d-flex justify-content-center align-items-center">
        <div className="col-md-6 text-start">
          <h4 className="teamupportfolio">
            Team up with us to bring <br /> your project to life
          </h4>
        </div>
        <div className="col-md-4">
          <button className="btn buttonfunctionaity">Contact Us →</button>
        </div>
      </div>

      {/* OurCollaborativeVentures component */}
      <OurCollaborativeVentures />
    </div>
  );
};

export default PortfolioHomecare;
